import React, {useEffect} from "react";
import {AppHeader} from "../components/AppHeader";
import DeleteForeverOutlinedIcon from '@mui/material/Icon';
import { Grid, Paper } from '@mui/material';

import { makeStyles } from  '@mui/styles';
import {useHistory, useLocation} from "react-router-dom";
import AlarmService from "../services/AlarmService";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TimeHelperService from "../services/TimeHelperService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
        boxShadow: "5px 5px 10px #aaaaaa",
    },

    paper_grey: {
        textAlign: 'center',
        color: "#555555",
        width: "100%",
        backgroundColor: theme.palette.greyScale.light,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
    },

    alarmsContainer: {
        display:"flex",
        justifyContent: "flex-start",
        aligntItems: "flex-start",
    },

    alarmlogs_container: {
        width: "calc(100% - 30px)",
        margin: "10px auto",
        padding: 1,
        borderRadius: 14,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 15%)",
        '&:last-child': {
            marginBottom: "3rem",
        }
    },

    alarmlogs_head: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        justifyContent: "space-between",
    },

    alarmlogs_head_unread: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        justifyContent: "space-between",
    },

    alarmlogs_body: {
        color: "#989ba4",
        fontSize: 14,
        backgroundColor: "#ffffff",
        padding: "20px 10px 20px 20px",
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        '& .time': {
            width: "90%",
            textAlign: "left",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& div': {
                width: "100%",
            }
        },
        '& .actions': {
            width: "10%",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& svg': {
                fontSize: 25,
            }
        },
    },

    message_cell: {
        fontSize: 16,
        lineHeight: "1.2",
        minHeight: 50,
        textAlign: 'left',
        letterSpacing: 0,
        paddingLeft: 15,
        display: "flex",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
        }
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    curola: {
        maxWidth: 40,
    }
}));

export const Alarms = props => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();
    const [doRefresh, setDoRefresh] = React.useState(false);
    const [alarms, setAlarms] = React.useState([]);

    useEffect(() => {
        console.log("Alarms.js - useEffect(): " + location.state.currentUser.username);
        const getAlarms = async () => {
            const alarma = await AlarmService.getAlarms(location.state.currentUser.username);
            setAlarms(alarma);
        };
        getAlarms().catch((err) => {
            console.log("Error fetching alarms.");
            setAlarms([]);
        });
        let entryString = '';
        alarms.forEach(alarm => {
            entryString += "Alarm:\n";
            entryString += JSON.stringify(alarm) + "\n";
        })
        console.log("All the alarms: \n" + entryString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doRefresh]);

    const handleRefresh = () => {
        console.log("Uppdatera listan!");
        setDoRefresh(!doRefresh);
    }


    const handleAlarmClick = (alarmId) => {
        let uri = "/alarm_detail";
        history.push({pathname: uri, state: {alarmId: alarmId, currentUser: location.state.currentUser}});
        console.log("alarmId: " + alarmId);
    };

    const handleDelete = (id, alarmName) => {
        alert("Är du säker på att du vill ta bort alarmet: " + alarmName + "?");
        let tenantUser = location.state.currentUser ? location.state.currentUser.username : "";
        const deleteAlarm = async (tenantUser) => {
            const alarma = await AlarmService.deleteAlarm(id, tenantUser);
            setAlarms(alarma);
        };
        deleteAlarm(tenantUser).catch((err) => {
            console.log("Error deleting alarm.");
        });
    }

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader/>
            <div className="content-pane content-pane-green">
                <Grid container direction="row" className={classes.alarmsContainer}>
                    <Grid item xs={12} className="title_heading">
                        <h1>Alla larm</h1>
                        <AutorenewRoundedIcon onClick={() => handleRefresh()} fontSize="large" />
                        <ArrowBackOutlinedIcon onClick={() => history.push({pathname: '/home', state: { currentUser: location.state.currentUser}})} />
                    </Grid>
                    {alarms.map((alarm) => (
                        <Paper sx={{borderRadius: 4}}
                               key={alarm.id}
                                variant="outlined"
                                className={classes.alarmlogs_container}>
                            <Grid container direction="row" className={alarm.read === true ? classes.alarmlogs_head : classes.alarmlogs_head_unread}>
                                <Grid item xs={12} onClick={() => handleAlarmClick(alarm.id)} className={classes.message_cell}>
                                    <b>{alarm.id + ".  " + alarm.ruleName}</b>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.alarmlogs_body}>
                                <Grid item xs={2} style={{textAlign:"left"}}>
                                    <img className={classes.curola} src="/images/CurolaMiniAlarm.svg" alt="Curola alarmerar."/>
                                </Grid>
                                <Grid item xs={9} className="time">
                                    <div>Inkom den {alarm.time !== undefined ? TimeHelperService.getDateFromUTCDateString(alarm.time) : "inget datum angivet"}</div>
                                    <div>klockan {alarm.time !== undefined ? TimeHelperService.getTimeFromUTCDateString(alarm.time) : "inget klockslag angivet"}</div>
                                </Grid>
                                <Grid item xs={1} className="actions">
                                    <DeleteForeverOutlinedIcon onClick={() => handleDelete(alarm.id, alarm.id + ": " + alarm.ruleName)}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Grid>
            </div>
        </div>
    );
};