import React, {useEffect, useContext, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Grid, Paper} from "@mui/material";
import TimeHelperService from "../services/TimeHelperService";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ManIcon from '@mui/icons-material/Man';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {Map} from "./Map";


const useStyles = makeStyles((theme) => ({
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
    },
    paper_grey: {
        color: "#ffffff",
        fontSize: 16,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "15px auto",
        padding: 15,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    message_cell: {
        fontSize: 16,
        lineHeight: "1.2",
        minHeight: 30,
        textAlign: 'left',
        letterSpacing: 0,
        paddingLeft: 15,
        display: "flex",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
        }
    },

    form_field_time: {
        color: "#ffffff",
        lineHeight: "1",
        fontFamily: 'HurmeDesign',
        fontSize: "17px",
        fontWeight: "normal",
        border: "none",
        textAlign: "left",
        backgroundImage: "none !important",
        backgroundColor: "transparent",
        outline: "none",
    },

    events_container: {
        width: "calc(100% - 30px)",
        margin: "10px",
        padding: 1,
        borderRadius: 14,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 15%)",
        '&:last-child': {
            marginBottom: "3rem",
        }
    },

    events_head: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        justifyContent: "space-between",
    },

    events_head_unread: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(177,177,187,1) 0%, rgba(200,200,200,1) 100%)",
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        justifyContent: "space-between",
    },

    events_body: {
        color: "#989ba4",
        fontSize: 14,
        backgroundColor: "#ffffff",
        padding: "20px 10px 20px 20px",
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        '& .time': {
            width: "90%",
            textAlign: "left",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& div': {
                width: "100%",
            }
        },
        '& .actions': {
            width: "10%",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& svg': {
                fontSize: 25,
            }
        },
    },

    static_text: {
        color: "#ffffff",
        fontSize: 16,
        borderRadius: 14,
        border: "4px solid #b3d7a3",
        height: '67px',
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "17px 18px",
    },

    curola: {
        maxWidth: "37px",
    },
}));

export const EventCard = props => {

    const classes = useStyles();
    const curEvent = props.sensorEvent;

    let isMovement = false;
    const [headString, setHeadString] = useState("");
    const [eventType, setEventType] = useState("");

    useEffect(() => {
        console.log("Entering useEffect in EventCard.");
        if (curEvent.eventValues) {
            console.log("Found eventValues.");
            if (curEvent.eventValues.find(({name}) => name === "PIR")) {
                console.log("Was PIR event");
                let pirObject = curEvent.eventValues.find(({name}) => name === "PIR");
                if (pirObject.value === "1" || pirObject.value === "alarm") {
                    isMovement = true;
                    setHeadString("Rörelse");
                    setEventType("MOVEMENT");
                } else {
                    isMovement = false;
                    setHeadString("Stilla");
                    setEventType("STILL");
                }
            }
            if (curEvent.eventValues.find(({name}) => name === "remote_control")) {
                console.log("Was button event");
                setHeadString("Knappen intryckt");
                setEventType("BTN_PRESS");
            }
            if (curEvent.eventValues.find(({name}) => name === "Reachfar Position")) {
                console.log("Was position event");
                setHeadString("Position");
                setEventType("POSITION")
            }
            if (curEvent.eventValues.find(({name}) => name === "TretyTM4 SOS")) {
                console.log("Was TretyTM4 SOS");
                setHeadString("SOS");
                setEventType("SOS");
            }
        }
    }, []);

    const renderIcon = ((param) => {
        switch(param) {
            case "MOVEMENT":
                return <DirectionsWalkIcon/>;
            case "STILL":
                return <ManIcon/>;
            case "BTN_PRESS":
                return <TouchAppIcon/>;
            case "POSITION":
                return <LocationOnIcon/>;
            case "SOS":
                return <NotificationsActiveIcon style={{color: '#bb0000', marginRight: '10px'}}/>;
            default:
                return "";
        }
    });

/**
    const renderPosition = (() => {
        if (eventType === "POSITION") {
            let position = curEvent.eventValues.find(({name}) => name === "Reachfar Position");
            return <div></div>//<Map position={position.value}/>;
        }
    });
*/
    const renderPosition = (() => {
        let regex = "^[0-9]*\.[0-9]*[N|S] [0-9]*\.[0-9]*[W|E]";
        if (curEvent.eventValues.find(({value}) => value.match(regex))) {
            let position = curEvent.eventValues.find(({value}) => value.match(regex));
            console.log("**** found position!!! **** " + JSON.stringify(position));
            return <div><Map position={position.value}/></div>//<Map position={position.value}/>;
        }
        else {
            console.log("**** did NOT find position!!! ****");
        }
    });


    return (
        <div>
            <Paper sx={{borderRadius: 4}} key={curEvent.id} variant="outlined" className={classes.events_container}>
            <Grid container direction="row" className={isMovement === true ? classes.events_head : classes.events_head_unread}>
                <Grid item xs={12} className={classes.message_cell}>
                    {renderIcon(eventType)}{headString} - {curEvent.sensorName}
                </Grid>
            </Grid>
            <Grid container direction="row" className={classes.events_body}>
                <Grid item xs={2} style={{textAlign:"left"}}>
                    <img className={classes.curola} src="/images/CurolaMiniOK.svg"
                         alt="Curola meddelar."/>
                </Grid>
                <Grid item xs={10} style={{textAlign:"left"}}>
                    <div><small>Datum:</small> {curEvent.dateTime !== undefined ? TimeHelperService.getDateFromDateString(curEvent.dateTime) : "inget datum angivet"}
                        <br/><small>Tid:</small> {curEvent.dateTime !== undefined ? TimeHelperService.getTimeFromDateString(curEvent.dateTime) : "inget datum angivet"}</div>
                    <div>{curEvent.sensorName}</div>
                    {renderPosition()}
                </Grid>
            </Grid>
        </Paper>
        </div>
    );
};