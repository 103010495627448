import React from "react";
import {AppHeader} from "../components/AppHeader";
import {Grid} from "@mui/material";
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom';
import { makeStyles } from  '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    device_wrapper: {
        color: "#ffffff",
        fontSize: 16,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "15px auto",
        padding: 25,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    message_cell: {
        textAlign: 'left',
        height: "100%",
        '& span': {
            display: "inline-block",
            width: 110,
        }
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    }
}));


export const Devices = props => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1>Utrustning</h1>
                    </Grid>
                    <Grid container direction="row" className={classes.device_wrapper}>
                        <Grid item xs={12} className={classes.message_cell}>
                            <span>Namn:       </span><b>ZigBee Gateway</b><br/>
                            <span>ID:         </span><b>bf0d1ae3fce3b0855</b><br/>
                            <span>Kategori:   </span><b>wfcon</b><br/>
                            <span>Huvudenhet: </span><b>Ja</b><br/>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.device_wrapper}>
                        <Grid item xs={12} className={classes.message_cell}>
                            <span>Namn:       </span><b>PIR Motion Sensor</b><br/>
                            <span>ID:         </span><b>bf0f9c7f966ac62de</b><br/>
                            <span>Kategori:   </span><b>pir</b><br/>
                            <span>Batteri:    </span><b>99%</b><br/>
                            <span>Huvudenhet: </span><b>Nej</b>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ marginTop: "6%" }}
                            variant="contained"
                            color="secondary"
                            onClick={() => history.goBack()}
                            className="button_danger">
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};