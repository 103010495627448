import {Grid} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import {makeStyles} from "@mui/styles";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery80Icon from '@mui/icons-material/Battery80';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    buttonSmall: {
        display: "flex",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        textAlign: "left",
        justifyContent: "center",
        alignItems: "center",
        fullWidth: "true",
        background: "linear-gradient(0deg, rgba(220,220,220,1) 0%, rgba(240,240,240,1) 100%)",
        maxHeight: "50px",
    },

    buttonLabelSmall: {
        display: "flex",
        marginLeft: "5px",
        alignItems: "center",
        color: "#575757",
        maxHeight: "40px",
    },

    batteryNormal: {
        color: "#575757",
        textShadow: "inherit",
    },

    batteryWarning: {
        color: "#DDAA00",
        textShadow: "inherit",
    },

    batteryCritical: {
        color: "#FF0000",
        textShadow: "inherit",
    },

    batteryUnknown: {
        color: "#777777",
        textShadow: "inherit",
    }
}));

export const BatteryStatus = props => {
    const classes = useStyles();
    const devices = props.devices;
    const isMultiple = Array.isArray(devices);
    let battStat;
    let battLvl;
    let isTretyTM4Charging = false;
    console.log("isMultiple: " + isMultiple);

    const resolveBatteryLevel = () => {
        if (!isMultiple) {
            console.log("is a single device");
            let device = devices;
            if (device.family === "reachfar") {
                battStat = device.batteryLevel < 25 ? 3 : 99;
            } else if (device.family === "wolf") {
                battStat = device.batteryLevel;
            } else if (device.family === "tretyTM4") {
                battStat = device.batteryLevel;
                isTretyTM4Charging = device.status === 1;
                console.log("isTretyTM4Charging = " + isTretyTM4Charging);
            }
            switch (device.family) {
                case "wolf":
                    switch (device.batteryLevel) {
                        case 1:
                            battLvl = "Nätström";
                            break;
                        case 2:
                            battLvl = "Batteribackup";
                            break;
                        case 3:
                            battLvl = "Låg nivå";
                            break;
                        case 99:
                            battLvl = "Normal";
                            break;
                        default:
                            battLvl = "unknown";
                    }
                    break;
                case "tretyTM4":
                    //falltrough...
                case "reachfar":
                    battLvl = device.batteryLevel + "%";
                    break;

                default:
                    battLvl = "unknown";
            }
        }

        if (isMultiple) {
            console.log("devices array was >1 in length.");
            let overallBattStat = 0;
            devices.forEach((device) => {
                if (device.family === "wolf") {
                    if (!(overallBattStat === 2 || overallBattStat === 3)) {
                        console.log("setting overallBattStat to: " + device.batteryLevel);
                        overallBattStat = device.batteryLevel;
                    }
                } else if (device.family === "reachfar" || device.family === "tretyTM4") {
                    if (!(overallBattStat === 2 || overallBattStat === 3)) {
                        if (device.batteryLevel < 25) {
                            overallBattStat = 3;
                        } else {
                            overallBattStat = 99;
                        }
                    }
                }
            });
            battStat = overallBattStat;
        }
    }

    resolveBatteryLevel();

    const resolveStyle = () => {
        if (battStat === 1) {
            return classes.batteryNormal;
        } else if (battStat === 2) {
            return classes.batteryWarning;
        } else if (battStat === 3) {
            return classes.batteryCritical;
        } else if (battStat > 3 && battStat < 20) {
            return classes.batteryCritical;
        } else if (battStat >= 20 && battStat < 50) {
            return classes.batteryWarning;
        } else if (battStat > 50 && battStat < 80) {
            return classes.batteryNormal;
        } else if (battStat > 80) {
            return classes.batteryNormal;
        } else {
            return classes.batteryUnknown;
        }
/**
        switch (battStat) {
            case 1:
                return classes.batteryNormal;
            case 2:
                return classes.batteryWarning;
            case 3:
                return classes.batteryCritical;
            case 99:
                return classes.batteryNormal;
            case 100:
                return classes.batteryNormal;
            default:
                return classes.batteryUnknown;
        }
*/
    }

    const resolveIcon = () => {
        if (!isMultiple) {
            let device = devices;
            if (device.family === "tretyTM4" && isTretyTM4Charging) {
                return (
                    <>
                        <BatteryChargingFullIcon
                            style={{transform: 'rotate(90deg)'}}
                            fontSize="large"/>
                        {battLvl}<small><i>[laddar]</i></small>
                    </>
                );
            }
            switch (battStat) {
                case 1:
                    return (
                        <>
                            <BatteryFullIcon
                                style={{transform: 'rotate(90deg)'}}
                                fontSize="large"/>
                            {battLvl}
                        </>
                    );
                case 2:
                    return (
                        <>
                            <Battery50Icon
                                style={{transform: 'rotate(90deg)'}}
                                fontSize="large"/>
                            {battLvl}
                        </>
                    );
                case 3:
                    return (
                        <>
                            <Battery20Icon
                                style={{transform: 'rotate(90deg)'}}
                                fontSize="large"/>
                            {battLvl}
                        </>
                    );
                default:
                    return (
                        <>
                            <BatteryFullIcon
                                style={{transform: 'rotate(90deg)'}}
                                fontSize="large"/>
                            {battLvl}
                        </>
                    );
            }
        } else {
            return (
                <>
                    <BatteryFullIcon
                        style={{transform: 'rotate(90deg)'}}
                        fontSize="large"/>
                </>
            );
        }
    }

    return (
        <div className={resolveStyle()}>
            <Stack direction="row" alignItems="center" gap={1}>
                {resolveIcon()}
            </Stack>
        </div>
    );
}