import React, { useEffect } from "react";
import { Grid, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UserService from "../services/UserService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
        margin: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
    },

    static_text: {
        color: "#ffffff",
        fontSize: 16,
        borderRadius: 14,
        border: "4px solid #b3d7a3",
        height: '64px',
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "17px 18px",
    },

    form_container: {
        padding: "10px",
    },

    paper_transparent: {
        width: "100%",
        backgroundColor: "transparent",
        border: "none",
        margin: "0px",
        padding: "0px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonAlarmRed: {
        paddingTop: "30px !important",
        paddingBottom: "30px !important",
        paddingLeft: "20px",
        textAlign: "left",
        justifyContent: "center",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },

    dosans_wrapper: {
        borderRadius: "1.5em",
        border: "4px solid #b3d7a3",
        height: '64px',
        width: "calc(100% - 40px)",
        maxWidth: '380px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 5,

    },

    story_text: {
        fontSize: "13px",
        fontWeight: "normal",
        display: "inline",
        color: "#8b0000",
        padding: '5px',
        align: 'left',
    },

    dropdown_style: {
        autoWidth: true,
    },
    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "auto",
        padding: 25,
        marginBottom: 10,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },
}));



export const ConfirmLogin = props => {

    const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []

    });
    const [messageType, setMessageType] = React.useState('success');
    const [open, setOpen] = React.useState(false);

    const history = useHistory();
    const classes = useStyles();


    console.log("history", history);
    console.log("classes", classes);


    useEffect(() => {
        const getUser = async () => {
            const userissimo = await UserService.getUser();
            setUser(userissimo);
            console.log("userissimo in Confirm Login", userissimo);
        };
        getUser().catch((err) => {
            console.log("Error fetching user." + err);
        });
        console.log("The User after fetch in Confirm Login: \n" + JSON.stringify(user));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpUser = {...user, confirmed: true};
        console.log("userdata after setting confirmed: " + JSON.stringify(tmpUser));
        UserService.updateUser(tmpUser)
            .then((string) => {
                setMessageType('success');
                handleSnackBar();
            })
            .catch((err) => {
                setMessageType('error');
                handleSnackBar();
                console.log("Error updating rule: " + err);
            });
        history.push("/home");
    };

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChangePassword = () => {
        history.push("/change_password"); // /change_password
    }

/**
    const handleKlar = () => {
        console.log("handleKlar - user = " + user.confirmed);
        setUser({
            ...user,
            confirmed: true
        });
            const updateUser = async () => {
                const tmpUser = await UserService.updateUser(user);
                setUser(tmpUser);
            };
            updateUser().catch((err) => {
                console.log("Error updating user: " + err.toString());
            });
        history.push("/home");
    }
*/
    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
        console.log("Did set the user: " + user.toString());
    };

/**
    const handleLogout = () => {
        Auth.logout(() => { });
        history.push("/");
    };
*/
    return (
        <div style={{ textAlign: "center" }}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity="success">
                    {messageType === 'success' ? "Sparat" : "Misslyckades med att spara!"}
                </Alert>
            </Snackbar>
            <div className="content-pane content-pane-green" style={{paddingTop:25}}>
            <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{ marginBottom: 0 }}>Användaruppgifter</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>Uppdatera dina användaruppgifter eller ändra lösenord.</h4>
                        <br />
                    </Grid>
                    <Paper variant="outlined" className={classes.paper_transparent}>
                        <form onSubmit={handleSubmit} className={classes.form_element}>
                            <Grid item xs={12}>
                                <div className="form-field-label">Namn</div>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    variant="outlined"
                                    value={user.name}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Adress</div>
                                <TextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    variant="outlined"
                                    value={user.address}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Postnummer</div>
                                <TextField
                                    fullWidth
                                    id="zip"
                                    name="zip"
                                    variant="outlined"
                                    value={user.zip}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Ort</div>
                                <TextField
                                    fullWidth
                                    id="postalAddress"
                                    name="postalAddress"
                                    variant="outlined"
                                    value={user.postalAddress}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Mobil</div>
                                <TextField
                                    fullWidth
                                    id="mobilePhone"
                                    name="mobilePhone"
                                    variant="outlined"
                                    value={user.mobilePhone}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">E-post</div>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    value={user.email}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="title_heading" style={{marginTop:50}}>
                                <h1 style={{marginBottom:0}}>Sensorer:</h1>
                            </Grid>
                            {
                                user.devices.map((device) => (
                                <Grid item xs={12} className={classes.contact_person}>
                                    <div>Namn: {device.name}</div>
                                    <div>ID: {device.devId}</div>
                                    <div>Batterinivå: {device.batteryLevel}%</div>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                {/* <Button color="error" variant="contained" onClick={() => handleChangePassword()}>
                                    Ändra lösenord
                                </Button> */}
                                <Button
                                    style={{ marginTop: "6%" }}
                                    onClick={() => handleChangePassword()}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.buttonAlarmRed}>
                                    Ändra lösenord
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="default" variant="contained" type="submit" onSubmit={() => handleSubmit()}>
                                    Klar
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Button color="primary" variant="contained" onClick={() => handleLogout()}>
                                    Logga ut
                                </Button>
                            </Grid> */}
                        </form>
                    </Paper>

                </Grid>
            </div>
        </div>
    );
};