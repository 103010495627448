import moment from "moment";
import TimeHelperService from "../services/TimeHelperService";

    function checkForIOS() {
        if (navigator.standalone) {
            return false;
        }

        const ua = window.navigator.userAgent;
        const webkit = !!ua.match(/WebKit/i);
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i)
        const isIOS = isIPad || isIPhone;
        const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

        const today = moment().toDate();
        const installPromptDate = localStorage.getItem("installPrompt");
        let lastPrompt = null;
        let days = null;
        if (installPromptDate !== null) {
            lastPrompt = moment(TimeHelperService.parseDateTimeString(installPromptDate));
            days = moment(today).diff(lastPrompt, "days");
        }
        let prompt = (isNaN(days) || days > 30) && isIOS && isSafari;

        if (prompt && "localStorage" in window) {
            let dateString = today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate()
                    + "T" + today.getHours()
                    + ":" + today.getMinutes()
                    + ":" + today.getSeconds();
            localStorage.setItem("installPrompt", dateString);
        }

        //return {isIOS, isSafari, prompt};
        return isIOS;
    }

export default function useIsIOS() {
    //let checkIOS = checkForIOS();
    //return checkIOS.isIOS;
    return checkForIOS();
}

    /**
export default function useIsIOS() {
    const [isIOS, setIsIOS] = useState({});

    useEffect(() => {
        setIsIOS(checkForIOS());
        return() => console.log("CLEANUP INSTALL PROMPT", isIOS);
    });

    return isIOS;
}
 */