import Cookies from "universal-cookie";

class EventService {

    async getEvents(tenantUser, page, size, sortcol) {
        const cookies = new Cookies();
        let event = [];
        console.log("getEvents");
        let uri = tenantUser ? "/api/userevents-paged/" + tenantUser : "/api/userevents-paged";
        console.log("EventService.js - getEvents(), uri = " + uri);
        await fetch(process.env.REACT_APP_API_URL + uri + "?page=" + page + "&size=" + size + "&sortcol=" + sortcol, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getEvents." + JSON.stringify(data));
                event = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return event;
    }

    async getEvent(eventId, tenantUser) {
        const cookies = new Cookies();
        let event = '';
        console.log("getEvent: " + eventId);
        let uri = tenantUser ? "/api/userevent/" + eventId + "/" + tenantUser : "/api/userevent/" + eventId;
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getEvent:" + JSON.stringify(data));
                event = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return event;
    }

    async getGraphEvents(ruleId, historyLength) {
        const cookies = new Cookies();
        let event = '';
        console.log("getGraphEvents: " + ruleId, historyLength);
        await fetch(process.env.REACT_APP_API_URL + "/api/userevent/firstmovementinrule?ruleId=" + ruleId + "&historyLength=" + historyLength, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getGraphEvents:" + JSON.stringify(data));
                event = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return event;
    }
}

export default new EventService();

