import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import './index.css';
import './assets/fonts/hurme.ttf';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";


const theme = createTheme({
    palette: {
        primary: {
            light: "#c1f4c2",
            main: "#a2cf98",
            dark:"#6faf5a",
        },
        secondary: {
            light: "#ff8080",
            main: "#e30613",
            dark: "#8d1a1c",
            contrastText: '#ffffff',
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark:"#d32f2f",
        },
        greyScale: {
            blueish: "#A6ABB6",
            middleDark: "#535360",
            dark: "#40424D",
            light: "#EDEDED",
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                padding: '10px',
                marginBottom: '10px',
                height: 'stretch',
                width: '100%',
            },
        },
        MuiSwitch: {
            switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#a6abb6"
            },
            colorSecondary: {
                "&$checked": {
                    // Controls checked color for the thumb
                    color: "#6faf5a"
                }
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "#a6abb6",
                "$checked$checked + &": {
                    // Controls checked color for the track
                    opacity: 0.7,
                    backgroundColor: "#ededed"
                }
            }
        },
        MuiMenu: {
            paper: {
                width: "30%",
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: "16px",
            }
        }
    },
    fontFamily: "Roboto",

    MuiButton: {
        root: {
            borderRadius: "8em",
            maxWidth: '150px',
            maxHeight: '30px',
            minWidth: '150px',
            minHeight: '30px',
        },
    },

});

ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
                <Router>
                    <App />
                </Router>
        </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
