import {Grid} from "@mui/material";
import React from "react";
import {useState} from "react";
import {makeStyles} from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import {AppHeader} from "../components/AppHeader";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useHistory, useLocation } from "react-router-dom";
import {BatteryStatus} from "../components/BatteryStatus";
import TimeHelperService from "../services/TimeHelperService";
import {Map} from "../components/Map";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "auto",
        padding: 10,
        marginBottom: 10,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    batteryNormal: {
        color: "#575757",
        textShadow: "inherit",
    },

    batteryWarning: {
        color: "#DDAA00",
        textShadow: "inherit",
    },

    batteryCritical: {
        color: "#FF0000",
        textShadow: "inherit",
    }
}));

export const BatteryInfo = props => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [devices, setDevices] = useState(location.state.devices);

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{marginBottom:0}}>Batteristatus</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.goBack()} />
                    </Grid>
                    { devices ?
                        devices.map((dev) => (
                            <Grid sx={{width: "calc(100% -30px)", marginLeft: "15px", marginBottom: "15px"}} item xs={12} className={classes.contact_person}>
                                <div>Namn: {dev.name}</div>
                                <div>ID: {dev.devId}</div>
                                <div>Senaste kontakt: {TimeHelperService.getDateAntTimeFomUTCDateString(dev.lastSeen)}</div>
                                <div>Batterinivå: <BatteryStatus devices={dev} iconOnly={false}/></div>
                                {dev.location ?
                                <div>
                                    <div>Position:</div>
                                    <div><Map position={dev.location.latLngString}/></div>
                                    <div>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(dev.location.dateTime)}</div>
                                    <div>Källa: {dev.location.locationSource}</div>
                                </div>
                                : <div></div>}
                            </Grid>
                        )) :
                        <div />
                    }
                </Grid>
            </div>
        </div>
    );
}

