import React from "react";
import Auth from "../Auth";

export const ErrorPage = props => {

    return (
        <div style={{ textAlign: "center" }}>
            <h1 className="title">404</h1>
            <h2 className="subtitle">Kattux kan inte hitta sidan du letar efter</h2>
            <button
                className="epic-button clickable"
                onClick={() =>
                    Auth.isAuthenticated ? props.history.push("/") : props.history.push("/login")
                }
            >
                Rädda mig!
            </button>
        </div>
    );
};