import Cookies from "universal-cookie";

class RuleService {

    async getRules(tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rules/" + tenantUser : "/api/rules/{tenantuser}";
        let rulios = [];
        console.log("getRules");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getRules." + data.toString());
                rulios = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return rulios;
    }

    async getRule(ruleId, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rule/" + ruleId + "/" + tenantUser : "/api/rule/" + ruleId;
        let rulio = '';
        console.log("getRule: " + ruleId);
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                rulio = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return rulio;
    }

    async createRule(values, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rule/" + tenantUser : "/api/rule";
        let rule = '';
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(values)
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from createRule." + data);
                rule = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return rule;
    }

    async setRuleActive(ruleId, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rule/isactive/" + ruleId + "/" + tenantUser : "/api/rule/isactive/" + ruleId;
        let rulios = [];
        console.log("getRules");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from setRuleActive." + data);
                rulios = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });

        return rulios;
    }

    async updateRule(rule, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rule/" + rule.id + "/" + tenantUser : "/api/rule/" + rule.id;
        let rulio = '';
        console.log("updateRule");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(rule),
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from updateRule." + data);
                rulio = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });

        return rulio;
    }


    async deleteRule(ruleId, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/rule/" + ruleId + "/" + tenantUser : "/api/rule/" + ruleId;
        let rulios = [];
        console.log("getRules");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from deleteRule." + data);
                rulios = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });

        return rulios;
    }


    createBody(values) {
        const startTime = values.start_hour + ":" + values.start_minute + ":00";
        const endTime = values.end_hour + ":" + values.end_minute + ":00";
        //TODO lägg till detta fält i formuläret.
        //const isActive = values.is_active;
        const dayOfWeek =
            values.mon === true ? "Mon," : "" +
                values.tue === true ? "Tue," : "" +
                    values.wed === true ? "Wed," : "" +
                        values.thu === true ? "Thu," : "" +
                            values.fri === true ? "Fri," : "" +
                                values.sat === true ? "Sat," : "" +
                                    values.sun === true ? "Sun" : "";

        const jsonString = JSON.stringify({
            'ruleName': values.rule_name,
            'startTime': startTime,
            'endTime': endTime,
            'ruleString': (values.rule_type === "1" ? "inactive" : "active"),
            'active': true,
            'dayOfWeek': dayOfWeek
        });
        console.log('the json string: ' + jsonString);
        return jsonString;
    }

}

export default new RuleService();

