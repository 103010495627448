import {useMemo} from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    Map: {
        height: 200,
        width: "100%",
    }
}));
export const Map = props => {

    const classes = useStyles();
    const position = props.position;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

    });

    if (!isLoaded) return <div>Loading...</div>

    //const center = useMemo(() => ({position}), []);
    const defaultMapOptions = {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: true
    };
    const center = () => {
        let lat = 0;
        let lng = 0;
        let parts = position.split(" ");
        if (parts[0].endsWith("N")) {
            lat = Number(parts[0].substring(0, parts[0].length - 1));
        } else {
            lat = Number("-" + parts[0].substring(0, parts[0].length - 1));
        }
        if (parts[1].endsWith("E")) {
            lng = Number(parts[1].substring(0, parts[1].length - 1));
        } else {
            lng = Number("-" + parts[1].substring(0, parts[1].length - 1));
        }
        /**
        console.log("Position string: " + JSON.stringify(position));
        if (position.substring(9, 10) === "N") {
            lat = Number(position.substring(0, 9));
        } else {
            lat = Number("-" + position.substring(0, 9));
        }
        if (position.substring(21, 22) === "E") {
            lng = Number(position.substring(11, 21));
        } else {
            lng = Number("-" + position.substring(11, 21));
        }
         */
        return {lat: lat, lng: lng};
    }

    return (
        <div>
            <GoogleMap zoom={10}
                       center={center()}
                       mapContainerClassName={classes.Map}
                       options={defaultMapOptions}>
                <Marker position={center()}/>
            </GoogleMap>
        </div>
    );
}