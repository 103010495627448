import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import UserService from "./services/UserService";

// Firebase configurations.
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
};

/**
 * This function requests permission for notifications from Firebase messaging API.
 * It returns a Promise that resolves with the Firebase notification
 * token if permission is granted,
 * or rejects with an error if the request fails or is denied.
 */
export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

/**
 * This function returns a subscription token from Firebase which
 * is used to identify a unique system.
 *
 * If the token is successfully retrieved, it will be logged and returned.
 * If not, the function will request notification permission
 * and log the result of the request.
 */
export const getTokenFromFirebase = () => {
    return getToken(messaging, {
        vapidKey:
            "BIpXHni3dDltkZCzXWOue4a6fshmdyHRS-wgooD2g6_tcnA5GDmYLC7m36smZuR_M-LaOxxrVtSsv_K2t7G7EHU",
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                return currentToken;
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
                Notification.requestPermission().then((permission) => {
                    console.log("Access for notification is ", permission);
                });
            }
        }).then((currentToken) => {
            //Send key to server.
            console.log("token before send: " + currentToken);
            UserService.putFCMToken(currentToken)
                .catch((err) => {
                    console.log("Error updating user details: " + err);
                });
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });
};

/**
 * The onMessage function from the Firebase Messaging API
 * is used to listen for incoming messages.
 */
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);