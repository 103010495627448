import React, {useContext, useEffect} from "react";
import {Alert, Grid, Paper} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import clsx from "clsx";
import UserService from "../services/UserService";
import { makeStyles } from "@mui/styles";
import {useHistory, useLocation} from "react-router-dom";
import {AppHeader} from "../components/AppHeader";
import DeleteIcon from '@mui/icons-material/Delete';
import {forEach} from "core-js/stable/dom-collections";
import async from "async";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import EditIcon from '@mui/icons-material/Edit';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {BatteryStatus} from "../components/BatteryStatus";

const useStyles = makeStyles((theme) => ({
    btn_fab: {
        width: "70px",
        height: "70px",
        background: "#EDEDED",
        margin: "20px",
        '& .MuiSvgIcon-root': {
            color: "#9bca86 !important",
            width: "62px",
            height: "62px",
        },
        '&:hover': {
            backgroundColor: "#f3f3f3",
        }
    },
    btn_fab_label: {
        color: "#ffffff",
        fontSize: 18,
        paddingLeft: 15,
    },
    paper_transparent: {
        display: 'flex',
        width: "100%",
        backgroundColor: "transparent !important",
        border: "none !important",
        margin: "0px",
        paddingBottom: "15px",
        justifyContent: 'center',
        alignItems: 'center',
    },

    form_contact_wrapper: {
        borderRadius: 14,
        border: "4px solid rgba(255,255,255,0.25)",
        height: 'auto',
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "12px !important",
        marginTop: "12px !important",
        marginRight: "auto",
        padding: "0.6rem",
        '& .MuiInputBase-formControl': {
            width: "100% !important",
        },
        '& .MuiButtonBase-root': {
            marginBottom: "5px !important",
        },
    },

    contact_person: {
        color: "#999999",
        fontSize: 15,
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        lineHeight: "1.2",
        textAlign: "left",
        background: "#FFFFFF",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        padding: 1,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
        marginBottom: 15,
        marginTop: "15px !important",
    },

    deviceHeader: {
        display: "table",
        width: "100%",
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        padding: 3,
        paddingLeft: 10,
        borderTopLeftRadius: 13,
        borderTopRightRadius: 13,
    }
}))
export const Settings = props => {

    const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []
    });
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [messageType, setMessageType] = React.useState('success');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        console.log("Settings useEffect");
        console.log("selected user: " + JSON.stringify(location.state.currentUser));
        const getUser = async () => {
            let userissimo = {};
            userissimo = await UserService.getUser(location.state.currentUser.username);
            await setUser(userissimo);
            console.log("user after fetch and set:", user);
        };
        getUser().catch((err) => {
            console.log("Error fetching user." + err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMessageChannelSubmit = (event) => {
        event.preventDefault();
        console.log("Submitting messageChannel: ", user);
        UserService.updateUser(user, location.state.currentUser.username)
            .then((string) => {
                setMessageType('success');
                handleSnackBar();
            })
            .catch((err) => {
                setMessageType('error');
                handleSnackBar();
                console.log("Error updating user details: " + err);
            });
    }

    const handleMessageChannelChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    }

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const resolveDeviceType = (device) => {
        switch (device.category) {
            case "HUB":
                return "Basstation";
                break;
            case "ALRMBTN":
                return "Trygghetslarm (hemma)";
                break;
            case "PIR":
                return "Rörelsesensor";
                break;
            case "WB":
                return "Mobilt trygghetslarm [armband]";
                break;
            case "POD":
                return "Mobilt trygghetslarm [pod]"
            default:
                return "okänd typ";
        }
    };

    const resolveDeviceFamily = (device) => {
        switch (device.family) {
            case "wolf":
                return "Tryggve";
                break;
            case "reachfar":
                return "Astrid";
                break;
            case "tretyTM4":
                return "Trety TM4";
                break;
            default:
                return "okänd";
        }
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <div>
                    <Alert onClose={handleSnackBarClose} severity="success">
                        {messageType === 'success' ? "Sparat" : "Misslyckades med att spara!"}
                    </Alert>
                </div>
            </Snackbar>
            <AppHeader/>
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block", paddingBottom: "30px"}}>
                    <Grid item xs={12} className="title_heading" style={{marginTop:50}}>
                        <h1 style={{marginBottom:0}}>Inställningar</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push({pathname: '/home', state: { currentUser: location.state.currentUser}})} />
                    </Grid>
                    <Grid item xs={12} className="title_heading">
                        <h2 style={{marginBottom:0}}>Larminställning:</h2>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", alignItems: "start", color: "#FFFFFF"}}>
                        <p><small>Här ställer du in om du vill ha dina larm som SMS eller som meddelanden i appen
                            (de dyker då upp som andra meddelanden du kan få från din webbläsare).</small></p>
                    </Grid>
                    <Grid item xs={12}>
                        <form  onSubmit={handleMessageChannelSubmit} id="updateMessageChannelForm">
                            <div className="form-field-label">Larm via</div>
                            <Select
                                sx={{
                                    boxShadow: "none",

                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                }}
                                id="messageChannel"
                                name="messageChannel"
                                fullWidth={true}
                                variant={"standard"}
                                disableUnderline
                                className={classes.form_field_time}
                                value={user.messageChannel}
                                onChange={handleMessageChannelChange}>
                                <MenuItem value={1}
                                          sx={{display: "flex",
                                              justifyContent: "center",
                                              fontFamily: "HurmeDesign",
                                              fontSize: "16px",
                                              color: "#92959e"}}
                                          divider={true}
                                          key={'SMS'}>SMS</MenuItem>
                                <MenuItem value={2}
                                          sx={{display: "flex",
                                              justifyContent: "center",
                                              fontFamily: "HurmeDesign",
                                              fontSize: "16px",
                                              color: "#92959e"}}
                                          divider={true}
                                          key={'Notiser'}>notiser (mobilapp)</MenuItem>
                            </Select>
                            <Button color="primary"
                                    variant="contained"
                                    type="submit"
                                    form="updateMessageChannelForm"
                                    style={{marginTop: "15px"}}>
                                Spara
                            </Button>
                        </form>
                    </Grid>

                    <hr/>

                    <Grid item xs={12} className="title_heading" style={{marginTop: "15px"}}>
                        <h2 style={{marginBottom:0}}>Enhetsinställningar:</h2>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", alignItems: "start", color: "#FFFFFF"}}>
                        <p><small>Inställningar för dina enheter. Här kan du ställa in namn på enheterna eller t.ex.
                        plats och storlek för säkra zoner (om du har en enhet med positionering).</small></p>
                    </Grid>

                    { user.devices ?
                        user.devices.map((device) => (
                            <Grid item xs={12} className={classes.contact_person} onClick={() => history.push({pathname: '/device_detail', state: { device: device, currentUser: user}})}>
                                <div className={classes.deviceHeader}>
                                    <span style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                        {resolveDeviceFamily(device)}
                                    </span>
                                    <span style={{display: "table-cell", width: "10%", padding: "4px"}}>
                                        <EditIcon/>
                                    </span>
                                </div>
                                <div style={{padding: "5px", paddingLeft: "10px"}}>
                                    <div><small>Namn:</small> {device.name}</div>
                                    <div><small>Typ:</small> {resolveDeviceType(device)}</div>
                                    <div style={{display: "table"}}>
                                        <span style={{display: "table-cell", width: "100%"}}><small>Batterinivå:</small> <BatteryStatus devices={device} iconOnly={false}/></span>
                                        {device.family === "reachfar" || device.family === "tretyTM4" ?
                                        <span style={{display: "table-cell", whiteSpace: "nowrap"}}><small>position: </small><GpsFixedIcon/></span>
                                            : <span/>}
                                    </div>
                                </div>
                            </Grid>
                        )) : <div /> }
                </Grid>
            </div>
        </div>
    );
};