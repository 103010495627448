import React, {useEffect} from "react";
import {AppHeader} from "../components/AppHeader";
import { Grid } from '@mui/material';
import {useLocation, useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RuleService from "../services/RuleService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { makeStyles } from  '@mui/styles';
import {LocalizationProvider} from "@mui/x-date-pickers";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    contentPane: {
        display: "flex",
        justifyContent: "center",
    },

    form_field_time_wrapper: {
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        borderRadius: "14px",
        border: "4px solid #b3d7a3",
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "18px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },

    form_field_time: {
        '& .MuiSelect-standard': {
            color: "#000000",
            width: "100%",
            lineHeight: "1",
            fontFamily: 'HurmeDesign',
            fontSize: "15px",
            fontWeight: "normal",
            border: "none !important",
            textAlign: "left",
            paddingTop: "10px",
            paddingBottom: "0px",
        },
        '& .MuiSelect-icon': {
            color: "#000000",
        },
    },

    daysFieldWrapper: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: "15px",
    },

    dateFieldWrapper: {
        display: "flex",
        marginBottom: "5px",
    },

    sectionWrapper: {
      marginBottom: "30px",
    },

    copy: {
        background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 100%)",
        padding: "6px 3px 7px 6px",
        marginTop: "-16px",
        marginLeft: "9px",
        marginRight: "25px",
        minWidth: "40px !important",
        borderRadius: "8px",
        boxShadow: "3px 3px 3px rgb(0, 0, 0, 0.1)",
    },

    checkbox_field: {
        display: "block",
        width: "calc(100% - 30px)",
        margin: "15px 15px 15px 30px",
        textAlign: "left",
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-label': {
            color: "#ffffff",
            fontFamily: 'HurmeDesign',
            fontSize: "18px",
            marginLeft: "15px",
            position: "relative",
            top: 3,
        }
    },

    checkbox_icon: {
        border: "4px solid #ffffff",
        borderRadius: "8px",
        width: "45px",
        height: "42px",
        backgroundColor: "transparent",
    },

    checkbox_checked_icon: {
        border: "4px solid #ffffff",
        borderRadius: "8px",
        width: "45px",
        height: "42px",
        backgroundColor: "transparent",
        position: "relative",
        '&:before': {
            display: 'block',
            width: "29px",
            height: "26px",
            borderRadius: "6px",
            background: "linear-gradient(34deg, rgba(1,98,255,1) 0%, rgba(13,227,254,1) 100%)",
            content: '""',
            position: "absolute",
            top: "4px",
            left: "4px",
        }
    },

    back_button: {
        color: "#ffffff !important",
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },

    toggleWeekGroup: {
        display: "flex",
        justifyContent: "center",
        width: "calc(100% - 30px)",
        marginBottom: "20px",
        padding: "8px",
        border: "4px solid #b3d7a3",
        borderRadius: "8px",
        '& button': {
            border: "1px solid #ffffff !important",
            color: "#afafaf",
            background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 60%) !important",
            fontSize: "13px",
            textTransform: "none !important",
        },
        '& button.Mui-selected': {
            background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 100%) !important",
            color: "#00c900",
            fontSize: "14px",
            textShadow: "2px 2px 3px #00FF00",
        },
        '& button.Mui-active': {
            background: "#ffffff",
        },
    },

    datePicker: {
        display: "flex",
        width: "100%"
    },

    dayLabel: {
        textTransform: "none !important",
        fontSize: "14px",
    },

    buttonDanger: {
        paddingTop: "30px !important",
        paddingBottom: "30px !important",
        paddingLeft: "20px",
        textAlign: "left",
        justifyContent: "center",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },

    textInput: {
      width: "100%",
    },

    infoText: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "white",
        fontStyle: "italic",
        marginLeft: "10%",
        marginRight: "10%",
        backgroundColor: "#5F9F63",
        padding: "2%",
        borderRadius: "14px",
        maxWidth: "400px"
    }
}));

export const RuleDetail = props => {

    const location = useLocation();
    const history = useHistory();
    const [rule, setRule] = React.useState({
        id: "",
        ruleName: "",
        startTime: "06:00:00",
        endTime: "10:00:00",
        ruleString: location.state.isOneTime ? 'active' : 'inactive',
        dayOfWeek: "",
        userId: "",
        active: true,
        date: "",
        oneTime: location.state.isOneTime
    });
    const [isLoaded, setLoaded] = React.useState(false);
    const [weekDays, setWeekDays] = React.useState([]);
    const [messageType, setMessageType] = React.useState('success');
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [allDaysSwitch, setAllDaysSwitch] = React.useState(false);
    const [isOneTime, setOneTime] = React.useState(location.state.isOneTime === true);

    useEffect(() => {
        let ruleId = location.state.ruleId;
        let currentUser = location.state.currentUser;
        let tmpNew = isNaN(ruleId);
        console.log("isNew = " + tmpNew +
            "\nruleId isNan = " + isNaN(ruleId) +
            "\nisLoaded = " + isLoaded);
        const getRule = async (ruleId) => {
            const rulio = await RuleService.getRule(ruleId, currentUser.username);
            setRule(rulio);
            setLoaded(true);
            setOneTime(rulio.oneTime);
            //setNew(false);
        };
        if (!isLoaded && !tmpNew) {
            getRule(ruleId).catch((err) => {
                console.log("Error fetching rules." + err);
                //setRule('');
                setLoaded( false);
                //setNew(false);
                setWeekDays([]);
            });
            console.log("The Rule after fetch: \n" + JSON.stringify(rule));
        }
        console.log("The rule: \n" + JSON.stringify(rule));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>{
        setWeekDays(rule.dayOfWeek === undefined || rule.dayOfWeek === "" ?
            [] :
            rule.dayOfWeek.split(','));
        setLoaded(true);
        setAllDaysSwitch(rule.dayOfWeek.split(',').length === 7);
    }, [rule]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const saveSuccessful = (isSuccess) => {
            if (isSuccess) {
                setMessageType('success');
                handleSnackBar();
            } else {
                setMessageType('error');
                handleSnackBar();
            }
        };
        if (rule.id === ""){
            RuleService.createRule(rule, location.state.currentUser.username)
                .then((string) => {
                    console.log("create was successful" + JSON.stringify(string));
                    //setNew(false);
                    //alert("the substring: " + JSON.stringify(string).substring(6, 8));
                    setRule({
                       ...rule,
                       id: string.id
                    });
                    console.log("id in the returning object: " + string.id);
                    saveSuccessful(true);
                })
                .catch((err) => {
               console.log("Error creating rule: " + err);
               saveSuccessful(false);
            });

        } else {
            RuleService.updateRule(rule, location.state.currentUser.username)
                .then((string) => {
                    //alert("Update was successful: " + JSON.stringify(string));
                    saveSuccessful(true);
                })
                .catch((err) => {
                console.log("Error updating rule: " + err);
                saveSuccessful(false);
            });
        }
    };

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOneTimeSwitch = (event) => {
        setOneTime(event.target.checked);
        setRule( {
           ...rule,
           ruleString: event.target.checked ? 'active' : 'inactive',
           oneTime: event.target.checked
        });
        console.log("ruleString: " + rule.ruleString +
        "\noneTime: " + rule.oneTime);
    }

    const handleWeekdays = (event, newWeekDays) => {
        setWeekDays(newWeekDays);
        setRule( {
           ...rule,
           dayOfWeek: newWeekDays.join()
        });
    };

    const handleTime = (event) => {
        switch (event.target.name) {
            case "startHour":
                let startHourArray = rule.startTime.split(":");
                startHourArray.splice(0, 1, event.target.value);
                let startHourString = startHourArray.join(':');
                console.log("Start Time String: " + startHourString);
                setRule( {
                   ...rule,
                   startTime: startHourString
                });
                break;
            case "startMinute":
                let startMinuteArray = rule.startTime.split(":");
                startMinuteArray.splice(1, 1, event.target.value);
                let startMinuteString = startMinuteArray.join(':');
                console.log("Start Time String: " + startMinuteString);
                setRule( {
                    ...rule,
                    startTime: startMinuteString
                });
                break;
            case "endHour":
                let endHourArray = rule.endTime.split(":");
                endHourArray.splice(0, 1, event.target.value);
                let endHourString = endHourArray.join(':');
                console.log("End Time String: " + endHourString);
                setRule( {
                    ...rule,
                    endTime: endHourString
                });
                break;
            case "endMinute":
                let endMinuteArray = rule.endTime.split(":");
                endMinuteArray.splice(1, 1, event.target.value);
                let endMinuteString = endMinuteArray.join(':');
                console.log("End Time String: " + endMinuteString);
                setRule( {
                    ...rule,
                    endTime: endMinuteString
                });
                break;
            default:
                console.log("Error setting time - no matching case: " + event.target.name);
        }
    };

    const handleChange = (event) => {
        setRule({
            ...rule,
            [event.target.name]: event.target.value
        });
    };

    const handleToggleAllDays = (event) => {
        const allWeek = (event.target.checked) ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] : [];
        setWeekDays(allWeek);
        setRule( {
            ...rule,
            dayOfWeek: allWeek.join()
        });
    };

    const handleDelete = () => {
        if (window.confirm("Är du säker på att du vill ta bort regeln: " + rule.ruleName + "?")) {
            const deleteRule = async () => {
                const rulios = await RuleService.deleteRule(rule.id, location.state.currentUser.username);
                setRule(rulios);
            };
            deleteRule().catch((err) => {
               console.log("Error deleting rule.");
            });
    
            if (deleteRule) history.push({pathname: '/rules', state: { currentUser: props.user}});
        }
    }

    const headingText = () => {
        if (rule && rule.id !== "" ) {
            return(<h1>Redigera regel</h1>);
        } else {
            return(isOneTime ? <h1>Ny engångsregel</h1> : <h1>Ny regel</h1>);
        }
    }

    const hours = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    const minutes = ['00','15','30','45'];

    return (
        <div>
            <AppHeader/>
            <div className="content-pane content-pane-green">
                <Grid container direction="row" className={classes.contentPane}>
                    { rule ?
                        <form onSubmit={handleSubmit}>
                            <Grid item xs={12} className="title_heading">
                                {headingText()}
                                <ArrowBackOutlinedIcon onClick={() => history.push(
                                    {pathname: '/rules', state: { currentUser: location.state.currentUser}})} />
                            </Grid>
                            <Grid item xs={12} className={classes.daysFieldWrapper}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isOneTime}
                                            icon={<span className={classes.checkbox_icon} />}
                                            checkedIcon={<span className={classes.checkbox_checked_icon} />}
                                        />}
                                    label="Engångsregel"
                                    onClick={handleOneTimeSwitch}
                                    className={classes.checkbox_field} />
                            </Grid>
                            <Grid item xs={12}>
                                {isOneTime ?
                                    <div className={classes.infoText}>En engångsregel larmar om det ÄR rörelse under tidsperioden det angivna
                                        datumet.</div>
                                    :
                                    <div className={classes.infoText}>En återkommande regel larmar om det INTE ÄR någon rörelse i tidspannet de angivna veckodagarna.</div>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Regelns namn</div>
                                <TextField
                                    id="ruleName"
                                    name="ruleName"
                                    variant="outlined"
                                    className={classes.textInput}
                                    value={rule.ruleName}
                                    onChange={handleChange} />
                            </Grid>
                            {/* <Grid item xs={12}>
                            <div className="form-field-label">...om</div>
                            <Select
                                fullWidth
                                id="ruleString"
                                name="ruleString"
                                variant="outlined"
                                value={rule.ruleString}
                                onChange={handleChange}>
                                <MenuItem value="inactive">ingen rör sig</MenuItem>
                                <MenuItem value="active">någon rör sig</MenuItem>
                            </Select>
                        </Grid> */}
                            <Grid item xs={12}>
                                <div className="form-field-label">Starttid</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div  className={classes.form_field_time_wrapper}>
                                    kl.
                                    <Select
                                        sx={{background: "transparent"}}
                                        id="startHour"
                                        name="startHour"
                                        variant="standard"
                                        fullWidth={true}
                                        className={classes.form_field_time}
                                        value={rule.startTime.substring(0, 2)}
                                        onChange={handleTime}>
                                        {hours.map((hour) => (
                                            <MenuItem
                                                sx={{display: "flex",
                                                    justifyContent: "center",
                                                    fontFamily: "HurmeDesign",
                                                    fontSize: "16px",
                                                    color: "#92959e"}}
                                                divider={true}
                                                value={hour.toString()} key={'sh' + hour}>{hour}</MenuItem>
                                        ))}
                                    </Select>
                                    :
                                    <Select
                                        sx={{background: "transparent"}}
                                        id="startMinute"
                                        name="startMinute"
                                        variant="standard"
                                        fullWidth={true}
                                        value={rule.startTime.substring(3, 5)}
                                        className={classes.form_field_time}
                                        onChange={handleTime}>
                                        {minutes.map((minute) => (
                                            <MenuItem
                                                sx={{display: "flex",
                                                    justifyContent: "center",
                                                    fontFamily: "HurmeDesign",
                                                    fontSize: "16px",
                                                    color: "#92959e"}}
                                                divider={true}
                                                value={minute.toString()} key={'sm' + minute}>{minute}</MenuItem>
                                        ))}
                                    </Select>
                                </div>

                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Sluttid</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div  className={classes.form_field_time_wrapper}>
                                    kl.
                                    <Select
                                        sx={{background: "transparent"}}
                                        labelId="end_hour"
                                        id="endHour"
                                        name="endHour"
                                        label="Sluttid (timme)"
                                        variant="standard"
                                        fullWidth={true}
                                        value={rule.endTime.substring(0, 2)}
                                        className={classes.form_field_time}
                                        onChange={handleTime}>
                                        {hours.map((hour) => (
                                            <MenuItem
                                                sx={{display: "flex",
                                                    justifyContent: "center",
                                                    fontFamily: "HurmeDesign",
                                                    fontSize: "16px",
                                                    color: "#92959e"}}
                                                divider={true}
                                                value={hour.toString()} key={'eh' + hour}>{hour}</MenuItem>
                                        ))}
                                    </Select>
                                    :
                                    <Select
                                        sx={{background: "transparent"}}
                                        labelId="end_minute"
                                        id="endMinute"
                                        name="endMinute"
                                        label="Sluttid (minut)"
                                        variant="standard"
                                        fullWidth={true}
                                        value={rule.endTime.substring(3, 5)}
                                        className={classes.form_field_time}
                                        onChange={handleTime}>
                                        {minutes.map((minute) => (
                                            <MenuItem
                                                sx={{display: "flex",
                                                    justifyContent: "center",
                                                    fontFamily: "HurmeDesign",
                                                    fontSize: "16px",
                                                    color: "#92959e"}}
                                                divider={true}
                                                value={minute.toString()}
                                                key={'em' + minute}>{minute}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Grid>
                            { !isOneTime ?
                                <div className={classes.sectionWrapper}>
                                    <Grid item xs={12} className={classes.daysFieldWrapper}>
                                        <div className="form-field-label">Dagar i veckan</div>
                                    </Grid>
                                    <Grid item xs={12} className={classes.daysFieldWrapper}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={allDaysSwitch}
                                                    icon={<span className={classes.checkbox_icon} />}
                                                    checkedIcon={<span className={classes.checkbox_checked_icon} />}
                                                />}
                                            label="Alla dagar"
                                            className={classes.checkbox_field}
                                            onClick={handleToggleAllDays} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ToggleButtonGroup id="dayOfWeek"
                                                           key="dayOfWeek"
                                                           name="dayOfWeek"
                                                           orientation="horizontal"
                                                           value={weekDays}
                                                           onChange={handleWeekdays}
                                                           className={classes.toggleWeekGroup}>
                                            <ToggleButton
                                                id="Mon"
                                                name="Mon"
                                                value="Mon"
                                            > {/* selected={rule.dayOfWeek.includes("Mon")} */}
                                                <div>Må</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Tue"
                                                name="Tue"
                                                value="Tue"
                                            > {/* selected={rule.dayOfWeek.includes("Tue")} */}
                                                <div>Ti</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Wed"
                                                name="Wed"
                                                value="Wed"
                                            > {/* selected={rule.dayOfWeek.includes("Wed")} */}
                                                <div>On</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Thu"
                                                name="Thu"
                                                value="Thu"
                                            > {/* selected={rule.dayOfWeek.includes("Thu")} */}
                                                <div>To</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Fri"
                                                name="Fri"
                                                value="Fri"
                                            > {/* selected={rule.dayOfWeek.includes("Fri")} */}
                                                <div>Fr</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Sat"
                                                name="Sat"
                                                value="Sat"
                                            > {/* selected={rule.dayOfWeek.includes("Sat")} */}
                                                <div>Lö</div>
                                            </ToggleButton>
                                            <ToggleButton
                                                id="Sun"
                                                name="Sun"
                                                value="Sun"
                                            > {/* selected={rule.dayOfWeek.includes("Sun")} */}
                                                <div>Sö</div>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </div> :
                                <div className={classes.sectionWrapper}>
                                    <Grid item xs={12} className={classes.dateFieldWrapper}>
                                        <div className="form-field-label">Datum</div><br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="sv">
                                            <MobileDatePicker
                                                className={classes.datePicker}
                                                value={rule.date}
                                                onChange={(newValue) => {
                                                    setRule({
                                                        ...rule,
                                                        date: new Date(newValue).toLocaleDateString("sv-SE"),
                                                    });
                                                    console.log("dateValue = " + rule.date);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </LocalizationProvider>
                                    </Grid>
                                </div>}
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained" type="submit">
                                    Spara
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:25}}>
                                <Button variant="contained" color="secondary" className={classes.buttonDanger} onClick={handleDelete}>
                                    Ta bort
                                </Button>
                            </Grid>
                        </form> :
                        <Grid item xs={12} className="title_heading">
                            <h1>Laddar...</h1>
                        </Grid> }
                </Grid>
            </div>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <div>
                    <Alert onClose={handleSnackBarClose} severity="success">
                        <div>{messageType === 'success' ? "Sparat" : "Misslyckades med att spara!"}</div>
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
};