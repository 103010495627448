import React, { useEffect } from "react";
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import EventService from "../services/EventService";
import Fab from "@mui/material/Fab";
import ArrowBackRoundedIcon from "@mui/material/Icon";
import TimeHelperService from "../services/TimeHelperService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
        margin: '10px',
    },
    paper_grey: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: "#555555",
        width: "100%",
        border: "1px solid #999999",
        backgroundColor: theme.palette.greyScale.light,
        padding: "5px",
        margin: '10px',
    },

    message_cell: {
        textAlign: 'left',
        height: "100%",
        borderRadius: 50,
        fontSize: 16,
        lineHeight: "1.2",
        letterSpacing: 0,
    },

    events_body: {
        color: "#989ba4",
        fontSize: 14,
        background: "linear-gradient(#9bd4ff, #0693ff)",
        margin: '6%',
        width: "90%",
        padding: 10,
        justifyContent: 'center',
        borderRadius: 10,

    },

    events_container: {
        width: "calc(100% - 40px)",
        margin: "10px auto",
        padding: 17,
        borderRadius: 13,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 20%)",
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },
    curola: {
        maxWidth: "30px",
    }
}));

export const EventDetail = props => {
    const classes = useStyles();

    //let { alarmId } = useParams();
    const location = useLocation();
    const history = useHistory();

    const [event, setEvent] = React.useState('');
    const [isLoaded, setLoaded] = React.useState(false);

    useEffect(() => {
        const eventId = Number.parseInt(location.pathname.match("(?!.*/).+")[0]);
        console.log("the pathname search: " + location.pathname.match("(?!.*/).+")[0]);

        const getEvent = async () => {
            const evento = await EventService.getEvent(eventId);
            setEvent(evento);
            setLoaded(true);
        };
        getEvent().catch((err) => {
            console.log("Error fetching alarms.");
            setEvent([]);
            setLoaded(false);
        });
        console.log("The event: \n" + event);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container spacing={3} direction="row">
                    <Grid item xs={12}>
                        <h2>Händelsegrupp</h2>
                    </Grid>
                    {isLoaded ?
                        <Paper variant="outlined" className={classes.events_container}>
                            <Grid item xs={12}>
                                <img className={classes.curola} src="/images/CurolaMiniOK.svg" alt="Curola meddelar." />
                                <br /> <br /><Divider className={classes.divider} /><br />
                            </Grid>
                            <Grid item xs={10} className={classes.message_cell}>
                                {/* Sensor Id: <b><i>{event.devId}</i></b>
                                    <Divider className={classes.divider}/><br/> */}
                                <b>Händelser</b><small><i> - i gruppen</i></small><br />
                                <ul>
                                    {event.sensorSubMessages.map((subMessage) => (
                                        <li key={subMessage.time}>
                                            <b>Händelse</b><br />
                                            <small> - Inkom den {subMessage.time !== undefined ? TimeHelperService.getDateFromDateString(subMessage.time) : "inget datum angivet"}</small><br />
                                            <small> - klockan {subMessage.time !== undefined ? TimeHelperService.getTimeFromDateString(subMessage.time) : "ingen tid angiven"}</small><br />
                                            <small> - rörelse: <b>{subMessage.value === "pir" ? "Ja" : "Nej"}</b></small><br /><br />
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        </Paper> :
                        <Paper variant="outlined" className={classes.events_body}>
                            <h2 >Loading...</h2>
                        </Paper>}
                    <Grid item xs={12}>
                        <Fab size="small" color={"greyScale"} onClick={() => history.goBack()}>
                            <ArrowBackRoundedIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};