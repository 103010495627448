
class TimeHelperService {

    parseDateTimeString(dateString){
        return new Date(dateString.substring(0, 4)
            + "-" + dateString.substring(5, 7)
            + "-" + dateString.substring(8, 10)
            + "T" + dateString.substring(11, 13)
            + ":" + dateString.substring(14, 16)
            + ":" + dateString.substring(17, 19));
        }

    getTimeFromDateString(dateString) {
        const dateTimeOptions = {hour: 'numeric', minute: 'numeric', second: 'numeric'};
        return new Intl.DateTimeFormat('sv-SE', dateTimeOptions).format(this.parseDateTimeString(dateString));
    }

    getDateFromDateString(dateString) {
        return new Intl.DateTimeFormat('sv-SE').format(this.parseDateTimeString(dateString));
    }
    getDateFromUTCDateString(dateString) {
        let utcDate = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return utcDate.toLocaleDateString('sv-SE', options);
    }
    getTimeFromUTCDateString(dateString) {
        let utcDate = new Date(dateString);
        return utcDate.toLocaleTimeString('sv-SE');
    }

    getDateAntTimeFomUTCDateString(dateString){
        let utcDate = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let date = utcDate.toLocaleDateString('sv-SE', options);
        let time = utcDate.toLocaleTimeString('sv-SE');
        return date + " " + time;
    }
}

export default new TimeHelperService();
