import React, {useEffect} from "react";
import {AppHeader} from "../components/AppHeader";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Grid, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { makeStyles } from  '@mui/styles';
import AlarmService from "../services/AlarmService";
import TimeHelperService from "../services/TimeHelperService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
        boxShadow: "5px 5px 10px #aaaaaa",
    },

    paper_grey: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: "#555555",
        width: "100%",
        backgroundColor: theme.palette.greyScale.light,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
    },

    alarmlogs_container: {
        width: "calc(100% - 30px)",
        margin: "10px auto",
        padding: 1,
        borderRadius: 14,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 15%)",
    },

    alarmlogs_head: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        justifyContent: "space-between",
    },    

    alarmlogs_body: {
        color: "#989ba4",
        fontSize: 14,
        backgroundColor: "#ffffff",
        padding: "20px 10px 20px 20px",
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        '& .details': {
            width: "90%",
            textAlign: "left",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& div': {
                width: "100%",
            }
        },
        '& .actions': {
            width: "10%",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& svg': {
                fontSize: 25,
            }
        },
    },

    message_cell: {
        fontSize: 16,
        lineHeight: "1.2",
        minHeight: 50,
        textAlign: 'left',
        letterSpacing: 0,
        paddingLeft: 15,
        display: "flex",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
        }
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    go_back_icon: {
        justifyContent: 'flex-start',
    },
    curola: {
      maxWidth: "40px",
    }
}));

export const AlarmDetail = props => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [alarm, setAlarm] = React.useState();

    useEffect(() => {
        //const alarmId = Number.parseInt(location.pathname.match("(?!.*/).+")[0]);
        //console.log("the pathname search: " + location.pathname.match("(?!.*/).+")[0]);
        let alarmId = location.state.alarmId;
        let currentUser = location.state.currentUser;
        const getAlarm = async (alarmId) => {
            const alarm = await AlarmService.getAlarm(alarmId, currentUser.username);
            setAlarm(alarm);
        };
        getAlarm(alarmId).catch((err) => {
            console.log("Error fetching alarms.");
            setAlarm([]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleDelete = (id, alarmName) => {
        const deleteAlarm = async () => {
            const alarma = await AlarmService.deleteAlarm(id, location.state.currentUser.username);
            setAlarm(alarma);
        };
        deleteAlarm().catch((err) => {
            console.log("Error deleting alarm.");
        });
        console.log("Alarmet deletades inte.")

        if (deleteAlarm) history.push({pathname: '/alarms', state: { currentUser: location.state.currentUser}});
    }

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader/>
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1>Larm</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push({pathname: '/alarms', state: { currentUser: location.state.currentUser}})} />
                    </Grid>
                    <Grid item xs={12} zeroMinWidth>
                        { alarm !== undefined ?
                        <Paper sx={{borderRadius: 4}} variant="outlined" className={classes.alarmlogs_container}>
                            <Grid container direction="row" className={classes.alarmlogs_head}>
                                <Grid item xs={12} className={classes.message_cell}>
                                    <b>{alarm.id}. {alarm.ruleName}</b>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.alarmlogs_body}>
                                <Grid item xs={2} style={{textAlign:"left"}}>
                                    <img className={classes.curola} src="/images/CurolaMiniAlarm.svg" alt="Curola alarmerar."/>
                                </Grid>
                                <Grid item xs={9} className="details">
                                    <div>{alarm.alarmType}</div>
                                    <div><small>Inkom den {alarm.time !== undefined ? TimeHelperService.getDateFromUTCDateString(alarm.time) : "inget datum angivet"}</small></div>
                                    <div><small>klockan {alarm.time !== undefined ? TimeHelperService.getTimeFromUTCDateString(alarm.time) : "ingen tid angiven"}</small></div>
                                    <div style={{marginTop:15}}><b>Meddelande:</b></div>
                                    <Divider className={classes.divider}/>
                                    <div>
                                        <p style={{color:"#989ba4"}}>{alarm.description}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={1} className="actions">
                                    <DeleteForeverOutlinedIcon onClick={() => { if(window.confirm("Är du säker?")) handleDelete(alarm.id, alarm.id + ": " + alarm.ruleName)}} />
                                </Grid>
                            </Grid>
                        </Paper> :
                        <div><p>Loading...</p></div>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};