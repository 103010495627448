import React, { useState, useEffect } from 'react';
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import moment from 'moment';
import Button from '@mui/material/Button';
import { makeStyles } from "@mui/styles";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useHistory } from "react-router-dom";
import EventService from "../services/EventService";
import RuleService from "../services/RuleService";
import ArrowBackOutlinedIcon from '@mui/material/Icon';
import { DateTime } from 'luxon';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
    },
    paper_grey: {
        color: "#ffffff",
        fontSize: 16,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "15px auto",
        padding: 15,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    message_cell: {
        textAlign: 'left',
        height: "100%",
        borderRadius: 50,
        fontSize: 16,
        lineHeight: "1.2",
        letterSpacing: 0,
    },

    sensorlogs_body: {
        color: "#989ba4",
        fontSize: 14,
        background: "linear-gradient(34deg, rgba(1,98,255,1) 0%, rgba(13,227,254,1) 100%)",
        boxShadow: "0px 4px 0px 0px rgba(91,146,66,0.45) inset",
        margin: "15px auto",
        maxWidth: "calc(100% - 30px)",
        justifyContent: 'center',
        borderRadius: 10,
        // '& .recharts-cartesian-axis-line, & .recharts-cartesian-axis-tick-line': {
        //     display: "none",
        // }
    },

    form_field_time: {
        color: "#ffffff",
        lineHeight: "1",
        fontFamily: 'HurmeDesign',
        fontSize: "17px",
        fontWeight: "normal",
        border: "none",
        textAlign: "left",
        backgroundImage: "none !important",
        backgroundColor: "transparent",
        outline: "none",
    },

    schedule_head: {
        color: "#ffffff",
        // background: "rgb(174,213,163)",
        // background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        justifyContent: "space-between",
    },

    events_container: {
        width: "calc(100% - 40px)",
        margin: "10px auto",
        padding: 17,
        borderRadius: 13,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 20%)",
    },

    static_text: {
        color: "#ffffff",
        fontSize: 16,
        borderRadius: 14,
        border: "4px solid #b3d7a3",
        height: '67px',
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "17px 18px",
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },
    curola: {
        maxWidth: "37px",
    },
    refresh: {
        '& svg': {
            marginTop: "0px !important",
            marginRight: "-16px !important",
        }
    },
    days_to_display: {
        display: "flex",
        justifyContent: "space-around",
        maxWidth: "calc(100% - 30px)",
        margin: "auto",
        '& .MuiButton-contained': {
            maxHeight: 60,
            maxWidth: "32%",
            '& .MuiButton-label': {
                color: "white",
                fontSize: 30,
            }
        }
    }

}));


export const Events = props => {

    const classes = useStyles();
    const history = useHistory();

    const [graphEvents, setGraphEvents] = React.useState([]);
    const [rules, setRules] = React.useState([]);
    const [selectedRule, setSelectedRule] = React.useState({
        id: 0,
        ruleName: "Inget larm (morgon)",
        startTime: "01:00:00",
        endTime: "10:00:00",
        ruleString: "inactive",
        dayOfWeek: "Mon,Tue,Wed,Thu,Fri,Sat,Sun",
        userId: 2,
        active: true

    });
    const [historyLength, setHistoryLength] = React.useState(7);
    const [loading, setLoading] = useState(false);
    const [selectedRuleUpd, setSelectedRuleUpd] = useState(0);

    const weekDayName = (weekDay) => {
        let weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        return weekdays[weekDay];
    };


    const convertToEpoch = (timestamp) => {
        let inputTS = DateTime.fromMillis(timestamp);
        console.log("Value of inputTS: " + inputTS.valueOf());
        let date1 = new Date(inputTS.valueOf());
        let timeString =
            "1970-01-01T"
            + ('0'+date1.getHours()).slice(-2)
            + ":" + ('0'+date1.getMinutes()).slice(-2)
            + ":" + ('0'+date1.getSeconds()).slice(-2);
        console.log(timeString + " = millis( " + DateTime.fromISO(timeString).valueOf() + " )");
        return DateTime.fromISO(timeString).valueOf();
    };

    const handleLinkClick = (componentName) => {
        console.log("clicked: " + componentName);
        history.push("/" + componentName);
    };


    useEffect(() => {
        console.log("useEffect: Load Rules");
            //get data
            const fetchData = async () => {
                const rulios = await RuleService.getRules();
                console.log("useEffect: Load Rules - after await rulios");
                setRules(rulios);
                if (rules.length > 0) {
                    console.log("got more than 0 rules => selecting the first one as default");
                    setSelectedRule(rules[0]);
                    selectedRule.startTime = convertToEpoch(DateTime.fromISO(selectedRule.startTime).valueOf());
                    console.log("after start time");
                    selectedRule.endTime = convertToEpoch(DateTime.fromISO(selectedRule.endTime).valueOf());
                    console.log("after end time");
                } else {
                    console.log("did not get any rules.");
                    setSelectedRuleUpd(0);
                }
            };

        fetchData().catch((err) => {
            console.time("Error fetching data: " + err.body);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("useEffect: setSelectedRule");
        if (rules.length > 0){
            let tmpSelectedRule = rules.find(({id}) => id === selectedRuleUpd);
            console.log("tmpSelectedRule: " + tmpSelectedRule.toString());
            tmpSelectedRule.startTime = convertToEpoch(DateTime.fromISO(tmpSelectedRule.startTime).valueOf());
            console.log("after start time 2");
            tmpSelectedRule.endTime = convertToEpoch(DateTime.fromISO(tmpSelectedRule.endTime).valueOf());
            console.log("after end time 2");
            setSelectedRule(tmpSelectedRule);
        } else {
            let tmpSelRule = {
                id: 0,
                ruleName: "Ingen regel (morgon)",
                startTime: 3600000,
                endTime: 32400000,
                ruleString: "inactive",
                dayOfWeek: "Mon,Tue,Wed,Thu,Fri,Sat,Sun",
                userId: 2,
                active: false
            }
            console.log("setting tmpSelRule");
            setSelectedRule(tmpSelRule);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleUpd]);

    useEffect(() => {
        console.log("useEffect: LoadGraphEvents");
        const fetchEvents = async (ruleId, historyLn) => {
            const eventos = await EventService.getGraphEvents(ruleId, historyLn);
            //setGraphEvents(eventos);
            setLoading(true);

            let tmpArray = [];
            const convertTimeData = () => {
                eventos.movements.forEach(function (movement) {
                    tmpArray.push({
                        "weekDay": movement.weekDay,
                        "time": convertToEpoch(parseInt(movement.time))
                    })
                });
                console.log(eventos);
            }
            convertTimeData();
            eventos.movements = tmpArray;
            setGraphEvents(eventos);
        }

        setHistoryLength(7);
        fetchEvents(selectedRule.id, historyLength).catch((err) => {
            console.log("Error fetching graphEvents." + err.toString());
        });
        console.log("after fetch events");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule]);

    const handleRules = (event) => {
        let eventValue = event.target.value;
        let tmpSelRule = rules.find(({id}) => id === eventValue);
        tmpSelRule.startTime = convertToEpoch(DateTime.fromISO(tmpSelRule.startTime).valueOf());
        tmpSelRule.endTime = convertToEpoch(DateTime.fromISO(tmpSelRule.endTime).valueOf());
        setSelectedRule(tmpSelRule);
        console.log("handleRules():\nthe selected value: " + event.target.value + "\n" +
            "tmpSelRule" + tmpSelRule.toString() + "\n" +
            "selectedRule: " + selectedRule.toString());
//        fetchEvents(selectedRule.id, historyLength);
        setGraphEvents([]);
    };

/**
    console.log('events.length', events.length);
    console.log('noOfPages ', noOfPages);
    console.log('page ', page);
    console.log('graphEvents ', graphEvents);
    console.log('history ', history);
    console.log('eventsData ', eventsData);
    console.log('rules ', rules);
*/



    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{ display: "block" }}>

                    <Grid item xs={12} className="title_heading">
                        <h1 style={{ marginBottom: 0 }}>Sensorloggen</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.goBack()} />
                    </Grid>
                    {/* <IconButton
                            onClick={() => handleRefresh()}
                            color="greyScale">
                            <AutorenewRoundedIcon fontSize="large" />
                        </IconButton> */}
                    <Grid item xs={12}>
                        <h3>Senaste dagarna</h3>
                    </Grid>
                    {loading ?
                        <div className="content-pane">
                            <Grid item xs={12} style={{marginTop:30}}>
                                <h2 style={{ marginBottom: 0 }}>Select Rules</h2>
                            </Grid>
                            <Grid item xs={12} className={classes.static_text}><FormControl fullWidth>
                                <Select
                                    name="ruleName"
                                    value={rules.id}
                                    onChange={handleRules}
                                    className={classes.form_field_time}
                                    defaultValue={rules[0].id}

                                >
                                    {rules?.map(rules => {
                                        return (

                                            <MenuItem key={rules.ruleName} value={rules.id} className="schedule_head">
                                                {rules.ruleName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl></Grid>
                                <Grid container className={classes.sensorlogs_body}>
                                    <LineChart
                                        width={390}
                                        height={370}
                                        data={graphEvents.movements}
                                        margin={{
                                            top: 20,
                                            right: 50,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="weekDay" stroke="white" name='day'
                                               tickFormatter={(weekDay) => weekDayName(weekDay)}
                                        />
                                        <YAxis dataKey='time' stroke="white" name='Time'
                                               tickFormatter={(time) => moment(time).format('HH:mm')}
                                               domain={[selectedRule.startTime - (1000 * 60 * 60), selectedRule.endTime + (1000 * 60 * 60)]}
                                               tickCount={6}
                                        />
                                        <ReferenceLine y={selectedRule.startTime} label="regel start" stroke="grey"/>
                                        <ReferenceLine y={selectedRule.endTime} label="regel slut" stroke="grey"/>
                                        <Tooltip/>
                                        <Line type="monotone" dataKey="time" stroke="white" strokeWidth="4"/>
                                    </LineChart>
                                </Grid>
                            <Grid item xs={12}>
                                <h2>Visa antal dagar:</h2>
                            </Grid>
                            <Grid item xs={12} className={classes.days_to_display}>
                                <Button variant="contained" size="small" color="primary">7</Button>
                                <Button disabled variant="contained" size="small" className="clear">14</Button>
                                <Button disabled variant="contained" size="small" className="clear">30</Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 50 }}>
                                <Button color="primary" variant="contained" size="small" onClick={() => handleLinkClick("event_list")}>Visa som lista</Button>
                            </Grid>
                        </div>
                        :
                        <Paper variant="outlined" className={classes.sensorlogs_body}>
                            <h2 >Loading...</h2>
                        </Paper>}
                    <Grid item xs={12} style={{ marginBottom: 35 }}><br /></Grid>
                </Grid>
            </div>
        </div>
    );
};