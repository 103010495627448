import React, {useContext, useEffect, useState} from "react";
import {AppHeader} from "../components/AppHeader";
import {Alert, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {HomeSingleUserButtons} from "../components/HomeSingleUserButtons";
import {HomeMultiUser} from "../components/HomeMultiUser";
import {InstallPWA} from "../components/InstallPWA";
import {useHistory, useLocation} from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {Map} from "../components/Map";
import TimeHelperService from "../services/TimeHelperService";
import DeviceService from "../services/DeviceService";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "auto",
        padding: 10,
        marginBottom: 10,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

}));
export const HealthAndLocation = props => {

    const classes= useStyles();
    const history = useHistory();
    const location = useLocation();
    const [devices, setDevices] = useState(location.state.devices);
    const [healthStatuses, setHealthStatuses] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log("HealthAndLocation");
        const getHealthStatus = async (deviceId) => {
            const deviceHealthStatus = await DeviceService.getHealthStatus(deviceId, null);
            setHealthStatuses(deviceHealthStatus);
        };
        getHealthStatus().catch((err) => {
            console.log("Error fetching HealthStatus." + err);
        });
        console.log("Health Status: \n" + JSON.stringify(healthStatuses));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDevice = (devId) => {
        let deviceArray = devices.filter((device) => device.devId === devId);
        if (deviceArray.length === 1) {
            return deviceArray[0];
        } else {
            return null;
        }
    }

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const updateHealthValues = (event, devId) => {
        event.preventDefault();
        handleSnackBar();
        console.log("updating healthValues for device: " + devId);
        const refreshHealthStatus = async (devId) => {
            const response = await DeviceService.refrashHealthStatus(devId, null);
            console.log("refreshHealthStatus: " + JSON.stringify(response));
        };
        refreshHealthStatus(devId).catch((err) => {
            console.log("Error saving device name." + err);
        });



    }

    const getHealthLocationInfo = (healthStatus) => {
        console.log("Current Health Status: \n" + JSON.stringify(healthStatus));
        let devajs = null;
        if (devices) {
            devajs = getDevice(healthStatus.devId);
            console.log("Current devajs: \n" + JSON.stringify(devajs));
            if (devajs) {
                return (
                    <div key={devajs.devId}>
                        <Grid sx={{width: "calc(100% -30px)", marginLeft: "15px", marginBottom: "15px"}} item xs={12}
                              className={classes.contact_person}>
                            <div>{devajs.name}</div>
                            { devajs.location ?
                                <div>
                                    <div>Senaste position:</div>
                                    <div><Map position={devajs.location.latLngString}/></div>
                                    <div><small><small><i>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(devajs.location.dateTime)}</i></small></small></div>
                                    <div><small><small><i>Källa: {devajs.location.locationSource}</i></small></small></div>
                                    <br/>
                                </div>
                            :
                                <div>
                                    <div><small><i>Ingen positionshistorik tillgänglig.</i></small></div>
                                </div>
                            }
                            {devajs.family === "reachfar" ?
                                <div>
                                    <div>Senaste hälsodata:</div>
                                    <div><small>Temp: </small>{healthStatus.bodyTemp} C<sup>o</sup></div>
                                    <div><small><small><i>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(healthStatus.bodyTempDate)}</i></small></small></div>
                                    <hr/>
                                    <div><small>Puls: </small>{healthStatus.hrt} bpm</div>
                                    <div><small><small><i>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(healthStatus.hrtDate)}</i></small></small></div>
                                    <hr/>
                                    <div><small>Blodtryck: </small>{healthStatus.bp} mm Hg</div>
                                    <div><small><small><i>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(healthStatus.bpDate)}</i></small></small></div>
                                    <hr/>
                                    <div><small>Syremättnad: </small>{healthStatus.o2} SpO<sub>2</sub></div>
                                    <div><small><small><i>Tidpunkt: {TimeHelperService.getDateAntTimeFomUTCDateString(healthStatus.o2Date)}</i></small></small></div>
                                    <hr/>
                                    <div><small><i>OBS! Uppgifterna är ungefärliga.</i></small></div>
                                    <div style={{marginTop: "15px"}}>
                                        <form onSubmit={(event) => updateHealthValues(event, healthStatus.devId)} id={"updateHealthValues_" + healthStatus.devId}>
                                            <Button color="primary" variant="contained" type="submit" form={"updateHealthValues_" + healthStatus.devId}>
                                                Uppdatera värdena
                                            </Button>
                                        </form>
                                    </div>
                                </div> :
                                <div></div> }
                        </Grid>
                    </div>
                );
            }
        } else {
            return (<div>Nothing to show!</div>);
        }
    }


    return (
        <div style={{textAlign: "center"}}>
            <AppHeader/>
            <div className="content-pane content-pane-green" key="heading">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{marginBottom:0}}>Hälsodata / position</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.goBack()} />
                    </Grid>
                    {healthStatuses.map((healthStatus) => (
                        getHealthLocationInfo(healthStatus)
                    ))}
                </Grid>
            </div>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <div>
                    <Alert onClose={handleSnackBarClose} severity="success">
                        <div>Mätning pågår, återkom om ett tag för att se nya värden...</div>
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
}