import {Grid, Paper} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";
import UserService from "../services/UserService";
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import {LoginContext} from "../contexts/LoginContext";


const useStyles = makeStyles((theme) => ({
    multiUser_container: {
        width: "calc(100% - 20px)",
        margin: "10px 10px 10px 10px",
        border: "2px solid rgba(164,207,146,1)",
        padding: 2,
        borderRadius: 10,
        background: "linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(230,230,230,1) 100%)",
    },

    multiUser_Blocks: {
        justifyContent: "center",
    },

    multiUser_HeaderGreen: {
        alignItems: "flex-start",
        display: "inline-flex",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        color: "#FFFFFF",
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        padding: 4,
        marginBottom: 4,
    },

    multiUser_HeaderRed: {
        alignItems: "flex-start",
        display: "inline-flex",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
        color: "#FFFFFF",
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        padding: 4,
        marginBottom: 4,
    },

    multiUser_name: {
        textAlign: "left",
        fontSize: "12pt",
        paddingLeft: 10,
    },

    multiUser_edit: {
      textAlign: "right"
    },

    smallButtonAlarmGreen: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "2px 2px 2px 2px",
        padding: 2,
        border: "1px solid rgba(125,184,90,1)",
        borderRadius: 7,
        background: "linear-gradient(90deg, rgba(169,209,154,1) 0%, rgba(164,207,146,1) 100%)",
        height: "40px",
    },

    smallButtonAlarmRed: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "2px 2px 2px 2px",
        padding: 2,
        border: "1px solid rgba(125,184,90,1)",
        borderRadius: 7,
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
        height: "40px",
    },

    smallButtonGrey: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "2px 2px 2px 2px",
        padding: 2,
        border: "1px solid rgba(125,184,90,1)",
        borderRadius: 7,
        background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 100%)",
        fontSize: 15,
        height: "40px",
    },

    iconStyle: {
        width: "25px",
        height: "25px",
    },

    batteryNormal: {
        color: "#000000",
    },

    batteryWarning: {
        color: "#DDAA00",
        textShadow: "inherit",
    },

    batteryCritical: {
        color: "#FF0000",
    },
}));

export const HomeMultiUser = props => {

    const classes = useStyles();
    const history = useHistory();
    const [users, setUsers] = useState([]);

    const handleLinkClick = (componentName) => {
        console.log("clicked: " + componentName);
        history.push("/" + componentName);
    };

    useEffect(() => {
        const getUser = async () => {
            const tmpUsers = await UserService.getMultiUsers();
            setUsers(tmpUsers);
        }
        getUser().catch((err) => {
            console.log("Error fetching users: " + err);
            setUsers([]);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkClassForBattery = (currentUser) => {
        if (parseInt(UserService.checkBatteryStatus(currentUser), 10) < 10) {
            console.log("battery status was: < 10");
            return classes.batteryCritical;
        } else if (parseInt(UserService.checkBatteryStatus(currentUser), 10) < 30) {
            console.log("battery status was: < 30");
            return classes.batteryWarning;
        } else {
            console.log("battery status was normal");
            return classes.batteryNormal;
        }
    }

    return (
        <Grid container direction={"row"}>
            <Grid item xs={12}>
                {users.map((currentUser) => (
                    <Paper key={currentUser.username} elevation={3} className={classes.multiUser_container}>
                        <Grid container direction="row" spacing={0}
                              onClick={() => history.push({pathname: "/user_detail", state: { currentUser: currentUser}})}
                              className={currentUser.hasAlarm ? classes.multiUser_HeaderRed : classes.multiUser_HeaderGreen}>
                            <Grid item xs={10}
                                        className={classes.multiUser_name}>
                                <b>{currentUser.name}</b>
                            </Grid>
                            <Grid item xs={2} className={classes.multiUser_edit}>
                            <EditIcon />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={0} className={classes.multiUser_Blocks}>
                            <Grid item xs={2}>
                                    <div
                                        onClick={() => history.push({pathname: "/alarms", state: { currentUser: currentUser}})}
                                        className={currentUser.hasAlarm ? classes.smallButtonAlarmRed : classes.smallButtonAlarmGreen}>
                                        <img alt="Det finns larm, gå till larmsidan."
                                             src="/images/icon-alarm2.svg"
                                            className={classes.iconStyle}/>
                                    </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={() => history.push({pathname: '/rules', state: { currentUser: currentUser}})}
                                    className={classes.smallButtonGrey}>
                                    <img alt="Se regelinställningar" src="/images/icon-schedule.svg" className={classes.iconStyle}/>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={() => history.push({pathname: '/event_list', state: { currentUser: currentUser}})}
                                    className={classes.smallButtonGrey}>
                                    <img alt="Se händelseloggen" src="/images/icon-reports.svg" className={classes.iconStyle}/>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={() => history.push({pathname: "/battery_info", state: {currentUser: currentUser}})}
                                    className={classes.smallButtonGrey}>
                                    <BatteryChargingFullIcon
                                        size="small"
                                        style={{ transform: 'rotate(90deg)' }}
                                        className={checkClassForBattery(currentUser)}/>
                                    <small className={checkClassForBattery(currentUser)}>{UserService.checkBatteryStatus(currentUser)}%</small>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={() => history.push({pathname: '/contact_info', state: { currentUser: currentUser}})}
                                    className={classes.smallButtonGrey}>
                                    <ContactPhoneIcon/>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={() => history.push({pathname: '/rule_detail', state: { currentUser: currentUser, ruleId: undefined, isOneTime: true}})}
                                    className={classes.smallButtonGrey}>
                                    <NotificationAddIcon/>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Grid>
        </Grid>
    );
}
