import React, {useEffect, useContext} from "react";
import { Grid } from '@mui/material';
import {Link, useHistory} from 'react-router-dom';
import { makeStyles } from  '@mui/styles';
import { LoginContext } from "../contexts/LoginContext";
import UserService from "../services/UserService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        maxWidth: '100%',
        color: "rgba(115,145,102,1)",
        fontSize: "larger",
        padding: "0px, 0px, 0px, 0px",
        margin: "0px, 0px, 0px, 0px",
    },

    curola: {
        width: "70%",
        maxWidth: "190px",
        height: "auto",
        fontColor: "rgba(115,145,102,1)",
        filter: "drop-shadow(1px 2px 1px rgb(0 0 0 / 0.3))",
    },

    multi: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 4,
        paddingBottom: 0,
        marginBottom: "5px",
        border: "2px solid rgba(125,184,90,1)",
        borderRadius: "30px",
        background: "linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(230,230,230,1) 100%)",
    },

    normal: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 4,
        paddingBottom: 0,
        marginBottom: "5px",
    }
}));


export const AppHeader = props => {

    const classes = useStyles();
    const { user, setUser } = useContext(LoginContext);
    const history = useHistory();

    useEffect(() => {
        const getUser = async () => {
            const tmpUser = await UserService.getUser();
            setUser(tmpUser);
        }
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    console.log("AppHeader.js - user: " + JSON.stringify(user));

    return (
        <div className="navigation-pane">
            {user.userType !== "multi" ?
                <Grid container direction="row" spacing={0} className={classes.normal}>
                    <Grid item xs={2} className={classes.grid}>
                        <Link to="/home">
                            <img alt="home link" className={classes.curola} src="/images/icon-home.svg" width="40" height="40"/>
                        </Link>
                    </Grid>
                    <Grid item xs={8} className={classes.grid}></Grid>
                    <Grid item xs={2} className={classes.grid}>
                        <img alt="user settings"
                             className={classes.curola}
                             src="/images/icon-user.svg"
                             width="40"
                             height="40"
                             onClick={() => history.push({pathname: '/user_detail', state: { currentUser: user}})}/>
                    </Grid>
                </Grid> :
                <Grid container direction="row" spacing={1} className={classes.multi}>
                    <Grid item xs={2} className={classes.grid}>
                        <Link to="/home">
                            <img alt="home link" className={classes.curola} src="/images/icon-home.svg" width="40" height="40"/>
                        </Link>
                    </Grid>
                    <Grid item xs={8} className={classes.grid}><i>{user.name}</i></Grid>
                    <Grid item xs={2} className={classes.grid}>
                        <img alt="user settings"
                             className={classes.curola}
                             src="/images/icon-user.svg"
                             width="40"
                             height="40"
                             onClick={() => history.push({pathname: '/user_detail', state: { currentUser: user}})}/>
                    </Grid>
                </Grid>
            }
        </div>
    );
};

