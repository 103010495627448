import { Grid, Paper } from "@mui/material";
import React from "react";
import { makeStyles } from  '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        //padding: theme.spacing(2),
        color: theme.palette.text.primary,
        width: "100%",
        border: "1px solid #b80000",
        backgroundColor: "#b8000055",
    },
    paper_grey: {
        //padding: theme.spacing(2),
        color: "#555555",
        width: "100%",
        border: "1px solid #999999",
        backgroundColor: "#b800000f",
    }
}));

export const InstallPWA = props => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper_grey}>
                Tryck på &nbsp;
                <img alt="Apple share icon" src="/images/appleShare.png"
                     heigh="20"
                     width="20"
                     />
                     &nbsp; och sedan "Lägg till på hemskärmen"<br/>
                för att installera appen lokalt.
            </Paper>
        </Grid>
    );
};
