import React, {useState, useEffect} from "react";
import {GoogleMap, useLoadScript, Marker, Circle} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import DeviceService from "../services/DeviceService";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
    },

    placesContainer: {
        margin: "10px",
        paddingTop: "5px",
        paddingBottom: "8px"
    },

    mapContainer: {
        height: "100%",
        width: "100%",
        border: "1px",
        borderRadius: "15px",
        borderColor: theme.palette.greyScale.dark,
    },

    comboboxInput: {
        width: "100%",
        padding: "5px",
        border: "1px solid #b3d7a3",
        borderRadius: "12px",
        fontFamily: 'HurmeDesign',
    },

    label: {
        display: "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "start",
        fontFamily: 'HurmeDesign',
        paddingLeft: "5px"
    },

    button: {
        display: "flex",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        paddingLeft: "30px",
        textAlign: "left",
        justifyContent: "flex-start",
        alignItems: "end",
        border: "1px",
        width: "100%",
        borderColor: theme.palette.greyScale.dark,
    },

    form_field_time: {
        '& .MuiSelect-standard': {
            color: "#000000",
            width: "100%",
            lineHeight: "1",
            fontFamily: 'HurmeDesign',
            fontSize: "15px",
            fontWeight: "normal",
            border: "none !important",
            textAlign: "left",
            paddingTop: "10px",
            paddingBottom: "0px",
        },
        '& .MuiSelect-icon': {
            color: "#000000",
        },
    },

    form_field_time_wrapper: {
        display: "flex",
        alignItems: "center",
        borderRadius: "14px",
        border: "none",
        width: "100%",
        maxWidth: '440px',
        textAlign: "left",
        justifyContent: "center",
        paddingBottom: "5px",
        backgroundColor: "transparent",
        marginBottom: "10px",
    },

    flicker: {
        color: theme.palette.text.primary,
        marginTop: 6,
        marginBottom: 6,
        '& .MuiSwitch-track': {
            backgroundColor: "#b0b0b0",
            opacity: "0.7",
        },
        '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: "#45ef24",
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: "#505050",
        },
    },
}));

const libraries = ["places"];
export const DeviceHomeLocation = (props) => {
    const classes = useStyles();
    const [deviceHomeLocation, setDeviceHomeLocation] = useState({
        "devId": "",
        "deviceAddress": "",
        "coordinates": {"lat": "61.007715", "lng": "14.545652"},
        "geoPerimeter": 25,
        "geoFenceEnabled": false
    });
    const [location, setLocation] = useState(null);
    //const [deviceAddress, setDeviceAddress] = useState(null);
    //const [geoPerimeter, setGeoPerimeter] = useState(25);
    //const [enabled, setEnabled] = useState(false);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    useEffect(() => {
        console.log("Entering resolve location!");
        if (navigator.geolocation) {
            return navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported, setting default.");
            setLocation({
                lat: "61.007715",
                lng: "14.545652"
            });
        }

        function success(position) {
            console.log("Success - " + JSON.stringify(JSON.parse('{"lat": "' + position.coords.latitude + '", "lng": "' + position.coords.longitude + '"}')));
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
        }

        function error() {
            console.log("Unable to retrieve your location, setting default.");
            setLocation({
                lat: "61.007715",
                lng: "14.545652"
            });
        }
    }, []);

    useEffect(() => {
        //Load homeLocation if it extists on device.
        const getDeviceHomeLocation = async (deviceId) => {
            console.log("getHomeLocation from server");
            const dHL = await DeviceService.getDeviceHomeLocation(deviceId, null);
            await setDeviceHomeLocation(previousState => {
                return {
                    ...previousState,
                    devId: dHL.devId === null || dHL.devId === "" ? deviceId : dHL.devId,
                    deviceAddress: dHL.deviceAddress,
                    coordinates: dHL.coordinates === null ? location : dHL.coordinates,
                    geoPerimeter: dHL.geoPerimeter === 0 ? 25 : dHL.geoPerimeter,
                    geoFenceEnabled: dHL.geoFenceEnabled
                }
            });
            console.log("Device Home Location (inside of async)): \n" + JSON.stringify(deviceHomeLocation));
        };
        if (location !== null) {
            getDeviceHomeLocation(props.device.devId).catch((err) => {
                console.log("Error fetching deviceHomeLocation: " + err);
            });
        }
        console.log("Device Home Location (outside of async): \n" + JSON.stringify(deviceHomeLocation));
        //update parameters.
    }, [location]);

    const handleSave = (event) => {
        event.preventDefault();
        console.log("Trying to save the following data: " +
            "\ndevId: " + deviceHomeLocation.devId +
            "\ncoordinates: " + JSON.stringify(deviceHomeLocation.coordinates) +
            "\ndeviceAddress: " + deviceHomeLocation.deviceAddress + "\ngeoPerimeter: " + deviceHomeLocation.geoPerimeter +
            "\ngeoFenceEnabled: " + deviceHomeLocation.geoFenceEnabled);
        if (deviceHomeLocation.coordinates && deviceHomeLocation.deviceAddress !== "") {
            console.log("All parameters set.");
            const saveHomeLocation = async () => {
                const dHL = await DeviceService.saveDeviceHomeLocation(deviceHomeLocation);
                setDeviceHomeLocation(dHL);
            };
            saveHomeLocation().catch((err) => {
                console.log("Error creating/saving deviceHomeLocation." + err);
            });
        } else if (deviceHomeLocation.geoFenceEnabled === false) {
            console.log("setting the geofence to off");
        } else {
            console.log("Need to specify a place first. ");
        }
    };

    const handlePerimeterChange = (event) => {
        setDeviceHomeLocation(prevState => {
            return {
                ...prevState,
                geoPerimeter: Number.parseInt(event.target.value)
            }
        });
    };

    const handleEnabledSwitch = (event) => {
        console.log("handleEnabledSwitch checkelichecked: " + event.target.checked);
        setDeviceHomeLocation(prevState => {
            return {
                ...prevState,
                geoFenceEnabled: event.target.checked
            }
        });
        console.log("handleEnabledSwitch deviceHomeLocation: " + deviceHomeLocation.geoFenceEnabled);
    };

    const setCoordinates = (coordinates) => {
        setDeviceHomeLocation(prevState => {
            return {
                ...prevState,
                coordinates: coordinates
            }
        });
    };

    const setDeviceAddress = (address) => {
        setDeviceHomeLocation(prevState => {
            return {
                ...prevState,
                deviceAddress: address
            }
        });
    };

    const logState = () => {
        console.log("#####\n State: " + JSON.stringify(deviceHomeLocation) + "\n#####");
    };


    if (!isLoaded && location !== null) {
        console.log("not loaded yet.")
        return <div>Loading...</div>;
    }

    const perimeters = [25, 50, 100, 200, 500];
    return (
        <div>
            <Grid container direction={"row"} className={classes.buttonContainer}>
                <form onSubmit={handleSave} id="deviceHomeAddressForm">
                    <Grid item xs={12} className={classes.label}>
                        <div className="form-field-label">Aktivera säker zon:</div>
                        <Switch
                            checked={deviceHomeLocation.geoFenceEnabled}
                            className={classes.flicker}
                            onChange={(event) => handleEnabledSwitch(event)}
                            name={"deviceHomeLocation.geoFenceEnabled"}
                            id={"deviceHomeLocation.geoFenceEnabled"}
                            />
                    </Grid>
                    {deviceHomeLocation.geoFenceEnabled ?
                        <>
                        <Map deviceHomeLocation={deviceHomeLocation}
                             setCoordinates={setCoordinates}
                             setDeviceAddress={setDeviceAddress}/>
                        <Grid item xs={12}>
                            <div  className={classes.form_field_time_wrapper}>
                                <Select
                                    sx={{
                                        boxShadow: "none",

                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                    }}
                                    id="deviceHomeLocation.geoPerimeter"
                                    name="deviceHomeLocation.geoPerimeter"
                                    fullWidth={true}
                                    variant={"standard"}
                                    className={classes.form_field_time}
                                    value={deviceHomeLocation.geoPerimeter === 0 ? 25 : deviceHomeLocation.geoPerimeter}
                                    onChange={handlePerimeterChange}>
                                    {perimeters.map((perimeter) => (
                                        <MenuItem
                                            sx={{display: "flex",
                                                justifyContent: "center",
                                                fontFamily: "HurmeDesign",
                                                fontSize: "16px",
                                                color: "#92959e"}}
                                            divider={true}
                                            value={perimeter.toString()} key={'perimeter:' + perimeter}>{perimeter} m</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                        </> : <Grid item xs={12}></Grid>
                    }
                    <Grid item xs={12}>
                        <Button fullWidth color="primary" variant="contained" type="submit" form="deviceHomeAddressForm">
                            Spara
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </div>
    );
}

function Map(props) {
    console.log("Map function props: " + JSON.stringify(props));
    const classes = useStyles();
    const [center, setCenter] = useState(null);
    //const [selected, setSelected] = useState({lat: props.position.lat, lng: props.position.lng});
    const [selected, setSelected] = useState(null);
    console.log("Start of Map-function." + JSON.stringify(center));

    const options = {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
    };

    useEffect(() => {
        setCenter({
                lat: parseFloat(props.deviceHomeLocation.coordinates.lat),
                lng: parseFloat(props.deviceHomeLocation.coordinates.lng)
            });
        setSelected({
            lat: parseFloat(props.deviceHomeLocation.coordinates.lat),
            lng: parseFloat(props.deviceHomeLocation.coordinates.lng)
        });
        //setSelectedValues(center.lat, center.lng, props.deviceHomeLocation.address);
    }, []);

    const setSelectedValues = (lat, lng, address) => {
        setCenter({lat: lat, lng: lng});
        setSelected({lat: lat, lng: lng});
        //set the equivalent state variables in the DeviceHomeLocation function
        props.setCoordinates({lat: lat, lng: lng});
        props.setDeviceAddress(address);
    };

    const circleOptions = {strokeColor: "#000000", strokeWeight: "1"};
    console.log("Some data in map construction: \ngeoPerimeter: " + props.deviceHomeLocation.geoPerimeter +
    " selected place: " + JSON.stringify(selected));

    return (
        <>
            <Grid item xs={12} className={classes.placesContainer}>
                <p style={{textAlign: "left", width: "100%"}} className="form-field-label"><small><i>Hemadress för armbandet:</i></small></p>
                <p style={{textAlign: "left", width: "100%"}} className="form-field-label">{props.deviceHomeLocation.deviceAddress}</p>
            </Grid>
            <Grid item xs={12} className={classes.placesContainer}>
                <PlacesAutocomplete setSelectedValues={setSelectedValues} />
            </Grid>
            <Grid item xs={12}>
                <div style={{height: "40vh", marginBottom: "10px"}}>
                    <GoogleMap
                        zoom={17}
                        center={center}
                        mapId={process.env.REACT_APP_GOOGLE_MAPS_CUROSENSE_MAP_ID}
                        mapContainerClassName={classes.mapContainer}
                        options={options}>
                        {selected &&
                            <>
                                <Marker position={center} />
                                <Circle
                                    center={center}
                                    radius={props.deviceHomeLocation.geoPerimeter}
                                    options={circleOptions}
                                />
                            </>
                        }
                    </GoogleMap>
                </div>
            </Grid>
        </>
    );
}

const PlacesAutocomplete = ({ setSelectedValues }) => {
    const classes = useStyles();
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
        console.log("Address: " + address);

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelectedValues(lat, lng, address);
        console.log("Address: " + address);
    };

     //TODO Gör om detta med MUI-komponenter

    return (
        <Combobox onSelect={handleSelect}>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className={classes.comboboxInput}
                placeholder="Sök ny adress"
            />
            <ComboboxPopover>
                <ComboboxList>
                    {status === "OK" &&
                        data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
};