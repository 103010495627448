import React, {useEffect} from "react";
import {AppHeader} from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import RuleService from "../services/RuleService";

import { makeStyles } from  '@mui/styles';
import {Redirect, useHistory, useLocation} from "react-router-dom";
import Fab from "@mui/material/Fab";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ManIcon from '@mui/icons-material/Man';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    contentPane: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start"
    },

    addContainer: {
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "20px",
        paddingTop: "20px",
        paddingLeft: "15px",
    },

    btn_fab: {
        width: 70,
        height: 70,
        background: "#EDEDED",
        marginTop: "20px",
        '& .MuiSvgIcon-root': {
            color: "#9bca86",
            width: 62,
            height: 62,
        },
        '&:hover': {
            backgroundColor: "#f3f3f3",
        }
    },
    btn_fab_label: {
        color: "#ffffff",
        fontSize: 18,
        paddingLeft: 15,
    },

    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
        boxShadow: "5px 5px 10px #aaaaaa",
    },
    paper_grey: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: "#555555",
        width: "100%",
        backgroundColor: theme.palette.greyScale.light,
        padding: "5px",
        margin: "10px",
        alignContent: "center",
    },

    schedule_container: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        padding: 1,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 15%)",
        '&:last-child': {
            marginBottom: "1rem",
        }
    },

    schedule_head: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(138,183,140,1) 0%, rgba(95, 159, 99, 1) 100%)",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        justifyContent: "space-between",
    },

    schedule_body: {
        color: "#989ba4",
        fontSize: 14,
        backgroundColor: "#ffffff",
        padding: 20,
        paddingTop: 5,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        '& .day': {
            width: "100%",
            textAlign: "left",
            display: "flex",
            justifyItems: "space-between",
            '& div:first-child': {
                width: "45%",
            }
        }
    },

    message_cell: {
        fontSize: 16,
        lineHeight: "1.2",
        textAlign: 'left',
        letterSpacing: 0,
        paddingLeft: 15,
        display: "flex",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
        }
    },

    button_cell: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: "100%",
        width: "100%",
        marginRight: 8,
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },
    deleteBin: {
        color: "#ffffff",
        paddingTop: 16,
        fontSize: 34,
    },
    flicker: {
        color: theme.palette.text.primary,
        marginTop: 6,
        marginBottom: 6,
        '& .MuiSwitch-track': {
            backgroundColor: "#b0b0b0",
            opacity: "0.7",
        },
        '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: "#45ef24",
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: "#505050",
        },
    },
    back_button: {
        color: "#ffffff !important",
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },
}));

export const Rules = props => {
    console.log("Entering Rules.");
    const [rules, setRules] = React.useState([]);

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    //if(location.state.currentUser == null || location.state.currentUser === ""){
    onbeforeunload = () => {
        window.location.href = "/";
    }

    useEffect(() => {
        console.log("Entering useEffect() inRules.");
        const getRules = async () => {
            const rulas = await RuleService.getRules(location.state.currentUser.username);
            setRules(rulas);
            let entryString = '';
            rulas.forEach(rule => {
                entryString += "Rule:\n";
                entryString += JSON.stringify(rule) + "\n";
            })
            console.log("All the rules (inside async-method): " + entryString);

        };
        getRules().catch((err) => {
            console.log("Error fetching rules." + err.toString());
        });
        let entryString = '';
        rules.forEach(rule => {
            entryString += "Rule:\n";
            entryString += JSON.stringify(rule) + "\n";
        })
        console.log("All the rules: \n" + entryString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRuleClick = (ruleId) => {
        let uri = "/rule_detail";
        history.push({pathname: uri, state: {ruleId: ruleId, currentUser: location.state.currentUser}});
        console.log("ruleId: " + ruleId);
    };

    const handleRuleActive = (id) => {
        const setActiveRule = async () => {
            const rulios = await RuleService.setRuleActive(id, location.state.currentUser.username);
            setRules(rulios);
        };
        setActiveRule().catch((err) => {
           console.log("Error setting rule active or not.");
        });
    }

    const handleCreateNew = () => {
        history.push({pathname: '/rule_detail', state: { currentUser: location.state.currentUser, ruleId: undefined, isOneTime: false}});
    }

/**
    const handleRefresh = () => {
        console.log("Uppdatera listan!")
    }
*/

    /**
     * TODO
     * Hela den här funktionen måste ses över när I18N appliceras.
     */
    const getPrettyDayOfWeek = (dayOfWeek) => {
        let dayOfWeekArray = dayOfWeek.split(',');
        let prettyDayOfWeekArray = [];
        dayOfWeekArray.map( currentDay => {
            switch (currentDay) {
                case "Mon":
                    prettyDayOfWeekArray.push("Må");
                    break;
                case "Tue":
                    prettyDayOfWeekArray.push("Ti");
                    break;
                case "Wed":
                    prettyDayOfWeekArray.push("On");
                    break;
                case "Thu":
                    prettyDayOfWeekArray.push("To");
                    break;
                case "Fri":
                    prettyDayOfWeekArray.push("Fr");
                    break;
                case "Sat":
                    prettyDayOfWeekArray.push("Lö");
                    break;
                case "Sun":
                    prettyDayOfWeekArray.push("Sö");
                    break;
                //default:
                //    currentDay = "";
            }
        });
        const dayMap = {"Må": 1, "Ti": 2, "On": 3, "To": 4, "Fr": 5, "Lö": 6, "Sö": 7, };
        prettyDayOfWeekArray.sort((a, b) => {
            return dayMap[a] - dayMap[b];
        });
        return prettyDayOfWeekArray.toString();
    }

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader/>
            <div className="content-pane content-pane-green">
                <Grid container direction="row" className={classes.contentPane}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{marginBottom:0}}>Regler</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push("/home")} />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "left", paddingLeft: 15}}>
                        <h4>Lägg till och ta bort scheman för olika tider på veckan.</h4>
                    </Grid>
                    <Grid item xs={12} className={classes.addContainer}>
                        <Fab className={classes.btn_fab} onClick={handleCreateNew}>
                            <AddIcon color="primary"/>
                        </Fab>
                        <span className={classes.btn_fab_label}>Skapa nytt schema</span>
                    </Grid>
                    { rules ? rules.map((rule) => (
                        <Grid item xs={12}>
                            <Paper sx={{borderRadius: 4}} key={rule.id} variant="outlined" className={classes.schedule_container}>
                                <Grid container direction="row" className={classes.schedule_head}>
                                    <Grid item xs={1} onClick={() => handleRuleClick(rule.id)} className={classes.message_cell}>
                                        { rule.ruleString === "active" ?
                                          <DirectionsWalkIcon/>
                                         :
                                            <ManIcon/>
                                        }
                                    </Grid>
                                    <Grid item xs={7} onClick={() => handleRuleClick(rule.id)} className={classes.message_cell}>
                                        <b>{rule.ruleName}</b>
                                    </Grid>
                                    <Grid item xs={3} className={classes.button_cell}>
                                        <Switch
                                            checked={rule.active}
                                            className={classes.flicker}
                                            onClick={() => {handleRuleActive(rule.id)}}
                                            name={"isActive_" + rule.id}
                                            inputProps={{ 'aria-label': 'primary checkbox'}}/>
                                    </Grid>
                                </Grid>
                                <Grid container onClick={() => handleRuleClick(rule.id)} className={classes.schedule_body}>
                                    <Grid item xs={12} style={{display: "flex", justifyContent: "end"}}>
                                        {rule.active ?
                                            <div style={{color: "black"}}>Aktiverad</div>
                                            :
                                            <div style={{color: "red"}}>Inaktiverad</div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} style={{display: "flex", justifyContent: "start"}}>
                                        Regeln larmar om det {rule.ruleString === "active" ? "ÄR " : "INTE ÄR någon "}rörelse inom tidsperioden.
                                    </Grid>
                                    <Grid item xs={8} style={{display: "flex", justifyContent: "start"}}>
                                        <div>Start-/sluttid:</div>
                                    </Grid>
                                    <Grid item xs={4} style={{display: "flex", justifyContent: "end"}}>
                                        <div style={{whiteSpace: "nowrap"}}>{rule.startTime.substring(0,5)} &mdash; {rule.endTime.substring(0,5)}</div>
                                    </Grid>
                                    <Grid item xs={8} style={{display: "flex", justifyContent: "start"}}>
                                        <div>{rule.oneTime ? "Datum:" : "Dagar i veckan:"}</div>
                                    </Grid>
                                    <Grid item xs={4} style={{display: "flex", justifyContent: "end"}}>
                                        {rule.oneTime ?
                                            <div>{rule.date}</div>
                                            :
                                            <div style={{whiteSpace: "nowrap"}}>{getPrettyDayOfWeek(rule.dayOfWeek)}</div>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )) : <h2>No rules defined.</h2> }
                </Grid>
            </div>
        </div>
    );
};



