import React, {useContext, useEffect, useState} from "react";
import {AppHeader} from "../components/AppHeader";
import {Grid} from "@mui/material";
import useIsIOS from "../components/useIsIOS";
import { InstallPWA } from "../components/InstallPWA";
import { LoginContext } from "../contexts/LoginContext";
import { makeStyles } from  '@mui/styles';
import {HomeSingleUserButtons} from "../components/HomeSingleUserButtons";
import {HomeMultiUser} from "../components/HomeMultiUser";
import UserService from "../services/UserService";
import AlarmService from "../services/AlarmService";
import {getTokenFromFirebase} from "../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    gradient_box_green: {
        height: "300px",
        paddingTop: "60px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "linear-gradient(90deg, rgba(169,209,154,1) 0%, rgba(164,207,146,1) 100%)",
        clipPath: "ellipse(80% 100% at 50% 0%)",
        '@media (max-width: 320px)': {
            height: "280px",
        },
    },

    gradient_box_red: {
        height: "300px",
        paddingTop: "60px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "linear-gradient(90deg, rgba(255,77,87,1) 0%, rgba(249,28,41,1) 100%)",
        clipPath: "ellipse(80% 100% at 50% 0%)",
        '@media (max-width: 320px)': {
            height: "280px",
        },
    },

    logo_wrapper: {
        paddingTop: 17,
        width: "100%",
        maxWidth: 414,
        '& img': {
            minHeight: 30, 
            maxHeight: 150, 
            maxWidth: "55%",
            // -webkit-filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
            filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
        }
    },

    buttonSection:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
/**
    button: {
        paddingTop: "15px !important",
        paddingBottom: "15px !important",
        paddingLeft: "30px",
        textAlign: "left",
        justifyContent: "flex-start",
    },

    buttonIcon: {
        display: "inline-flex",
    },

    buttonAlarmGreen: {
        paddingTop: "25px !important",
        paddingBottom: "25px !important",
        paddingLeft: "30px",
        textAlign: "left",
        justifyContent: "flex-start",
    },

    buttonAlarmRed: {
        paddingTop: "30px !important",
        paddingBottom: "30px !important",
        paddingLeft: "20px",
        textAlign: "left",
        justifyContent: "flex-start",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },

    buttonLabel: {
        marginLeft: "20px",
    },

    labelDescription: {
        display: "block",
        fontSize: "14px",
        marginTop: "5px",
    },
 */
    curolaContainer: {
        width: "100%",
        position: "absolute",
        top: "150px",
    },

    curola: {
        width: "50%",
        maxWidth: "190px",
        height: "auto",
    },

    versionInfo: {
        display: "flex",
        justifyContent: "center",
        color: "#BBBBBB",
        fontStyle: "italic",
    },
}));


export const Home = props => {
    const classes = useStyles();
    const { user, setUser } = useContext(LoginContext);
    console.log("User in Home.js: " + JSON.stringify(user));
    getTokenFromFirebase().catch(err => console.log(err));


    const {prompt} = useIsIOS();

    console.log("user in home: " + user);

    const app_name = process.env.REACT_APP_NAME;
    const app_version = process.env.REACT_APP_VERSION;
    console.log("App name: " + app_name +
        "\nApp version: " + app_version);

    return (
        <div style={{textAlign: "center"}}>
            <AppHeader/>
            <div className="content-pane">
                <Grid container direction="row">
                    <Grid item xs={12} zeroMinWidth>
                        <div className={user.hasAlarms ? classes.gradient_box_red : classes.gradient_box_green}>
                            <div className={classes.logo_wrapper}>
                                <img src="/images/CurosenseLogo-w.svg" alt="Curosense Logo"/>
                            </div>
                        </div>
                        {user.hasAlarms && user.userType !== "multi" ?
                            <div className={classes.curolaContainer}>
                                <img className={classes.curola} src="/images/CurolaRed.svg"
                                     alt="Det finns larm, gå till larmsektionen för att granska dina larm."/>
                            </div> :
                            <div className={classes.curolaContainer}>
                                <img className={classes.curola} src="/images/CurolaGreen.svg"
                                     alt="Allt verkar vara som vanligt."/>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.buttonSection}>
                        {user.userType !== "multi" ?
                            <HomeSingleUserButtons user={user}/> :
                            <HomeMultiUser user={user}/>
                        }
                        {prompt && <InstallPWA/>}
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{alignItems: "stretch"}}>
                    <Grid item xs={12} className={classes.versionInfo}>
                        Version: {app_version}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
