import Cookies from "universal-cookie";

class UserService {

    async getUser(tenantUser) {
        const cookies = new Cookies();
        let user = '';
        console.log("UserService.js - getUser(), tenantUser = " + tenantUser);
        let uri = tenantUser ? "/api/userdetails/" + tenantUser : "/api/userdetails";
        console.log("UserService.js - getUser(), uri = " + uri);
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                user = data;
                console.log("UserService.js - getUser in service:\n", user);

            })
            .catch((error) => {
                console.log("UserService.js - getUser, Det gick fel!\n" + error);
            });
        return user;
    }

    async setPassword(userPasswordDto, tenantUser) {
        const cookies = new Cookies();
        let userPwDto = "";
        console.log("setUserPassword");
        let uri = tenantUser ? "/api/userdetails/setpassword/" + tenantUser : "/api/userdetails/setpassword";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(userPasswordDto),
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - return from setPassword:\n" + data.toString());
                userPwDto = data;
            })
            .catch((error) => {
                console.log("UserService.js - setPassword, Det gick fel!\n" + error.toString());
                throw(error);
            });

        return userPwDto;
    }

    async updateUser(user, tenantUser) {
        const cookies = new Cookies();
        let userissimo = '';
        console.log("updateUser");
        let uri = tenantUser ? "/api/userdetails/" + tenantUser : "/api/userdetails";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(user),
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - updateUser, return from updateUser:\n" + JSON.stringify(data));
                userissimo = data;
            })
            .catch((error) => {
                console.log("UserService.js - updateUser, Det gick fel!\n" + error.toString());
            });

        return userissimo;
    }

    async putFCMToken(fcmToken) {
        const cookies = new Cookies();
        let machineID = this.getMachineID();
        let fcmTokenDto = {
            machineID: machineID,
            fcmToken: fcmToken
        };
        let tokenResponse = '';
        console.log("putFCMToken");
        let uri = "/api/userdetails/fcmtoken";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(fcmTokenDto),
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - putFCMToken, return from putFCMToken:\n" + JSON.stringify(data));
                tokenResponse = data;
            })
            .catch((error) => {
                console.log("UserService.js - putFCMToken, Det gick fel!\n" + error.toString());
            });

        return tokenResponse;
    }

    async removeFCMToken(){
        const cookies = new Cookies();
        let machineID = this.getMachineID();
        let tokenResponse = '';
        console.log("removeFCMToken");
        let uri = "/api/userdetails/removefcmtoken/" + machineID;
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - removeFCMToken, return from removeFCMToken:\n" + JSON.stringify(data));
                tokenResponse = data;
            })
            .catch((error) => {
                console.log("UserService.js - removeFCMToken, Det gick fel!\n" + error.toString());
            });

        return tokenResponse;

    }


    async addContact(contact, tenantUser) {
        const cookies = new Cookies();
        let userissimo = '';
        console.log("addcontact");
        let uri = tenantUser ? "/api/userdetails/addcontact/" + tenantUser : "/api/userdetails/addcontact";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "PUT",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(contact),
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - addContact, return from addContact:\n" + data);
                userissimo = data;
            })
            .catch((error) => {
                console.log("UserService.js - addContact, Det gick fel!\n" + error.toString());
            });

        return userissimo;
    }

    async deleteContact(contact, tenantUser) {
        const cookies = new Cookies();
        let userissimo = '';
        console.log("deleteContact");
        let uri = tenantUser ? "/api/userdetails/deletecontact/" + tenantUser : "/api/userdetails/deletecontact";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(contact),
        })
            .then(res => res.json())
            .then(data => {
                console.log("UserService.js - deleteContact, return from deleteContact:\n" + data);
                userissimo = data;
            })
            .catch((error) => {
                console.log("UserService.js - deleteContact, Det gick fel!\n" + error.toString());
            });

        return userissimo;
    }

    async getMultiUsers() {
        const cookies = new Cookies();
        let users = [];
        console.log("UserService.js - getMultiUsers");
        await fetch(process.env.REACT_APP_API_URL + "/api/multiuserdetails", {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                users = data;
                console.log("UserService.js - getMultiUsers in service:\n", JSON.stringify(users));

            })
            .catch((error) => {
                console.log("UserService.js - getUser, Det gick fel!\n" + error);
            });
        return users;
    }

    checkBatteryStatus(currentUser) {
        let devices = currentUser.devices;
        let batteryStatus = 100;
        devices.forEach(device => {
            batteryStatus = device.batteryLevel < batteryStatus ? device.batteryLevel : batteryStatus;
            console.log("HomeMultiUser - current user: " + currentUser.name + "checkBatteryStatus: " + batteryStatus);
        });
        return batteryStatus;
    }

    getMachineID() {
        let machineID = localStorage.getItem('machineID');

        if (!machineID) {
            machineID = crypto.randomUUID();
            localStorage.setItem('machineID', machineID);
        }
        return machineID;
    }
}
export default new UserService();

