import React, { useEffect } from 'react';
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Divider, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import EventService from "../services/EventService";
import { EventCard } from "../components/EventCard";
import TimeHelperService from "../services/TimeHelperService";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    refresh: {
        '& svg': {
            marginTop: "0px !important",
            marginRight: "-16px !important",
        }
    }


}));


export const EventList = props => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [events, setEvents] = React.useState([]);
/**
    const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []
    });
 */
    const [doRefresh, setDoRefresh] = React.useState(false);
    const itemsPerPage = 5;
    const [page, setPage] = React.useState(1);
    const [noOfPages, setNoOfPages] = React.useState(1000);
    const [sortcol, setSortcol] = React.useState("dateTime");

    const handleChange = (event, value) => {
        setPage(value);

        if (value > noOfPages) {
            setPage(value - 1);
        }
    };

    useEffect(() => {
        console.log("selected user: " + location.state.currentUser.username);
        const getEvents = async () => {
            const eventos = await EventService.getEvents(location.state.currentUser.username, page, itemsPerPage, sortcol);
            await setEvents(eventos.sensorEventDtoList);
            await setPage(eventos.currentPage);
            await setNoOfPages(eventos.totalPages);
            await setSortcol(eventos.sortColumn);

        };
        getEvents().catch((err) => {
            console.time("Error fetching events.");
            setEvents({});

        });
        let entryString = '';
        events.forEach(event => {
            entryString += "Events:\n";
            entryString += JSON.stringify(event) + "\n";
        })
        console.log("All the events: \n" + entryString);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doRefresh, page]);

/**
    const handleEventClick = (eventId) => {
        history.push("/event_detail/" + eventId);
        console.time("eventId: " + eventId);
    };
 */
    const handleRefresh = () => {
        console.log("Uppdatera listan!")
        setDoRefresh(!doRefresh);
    }


    console.log('events.length', events.length);
    console.log('noOfPages ', noOfPages);
    console.log('page ', page);



    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
            <Grid container direction="row" style={{ display: "block" }}>
                <Grid item xs={12} className="title_heading">
                    <h1 style={{ marginBottom: 0 }}>Händelselogg</h1>
                    <AutorenewRoundedIcon onClick={() => handleRefresh()} fontSize="large" />
                    <ArrowBackOutlinedIcon onClick={() => history.push("/home")} />
                </Grid>
                <br />
                <Grid item xs={12}>
                    <h3>Listvy</h3>
                </Grid>
                <Grid item xs={12}>
                        {events.length > 0 ? events
                        .map((curEvent) => (
                            <EventCard sensorEvent={curEvent}/>
                        )) : <b>Laddar...</b>
                        }
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Box component="span" paddingLeft={3}>
                        <Pagination
                            count={noOfPages}
                            page={page}
                            siblingCount={1}
                            boundaryCount={0}
                            onChange={handleChange}
                            defaultPage={1}
                            variant="outlined"
                            color="secondary"
                            size="large"
                            showFirstButton
                            classes={{ ul: classes.paginator }}
                        />
                    </Box>
                </Grid>
            </Grid>
            </div>
        </div>
    );
};