import React from "react";
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UserService from "../services/UserService";
import { Formik } from "formik";
import * as Yup from "yup";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { makeStyles } from '@mui/styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
  
    paper: {
        //padding: theme.spacing(2),
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
        margin: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
    },

    paper_transparent: {
        width: "100%",
        backgroundColor: "transparent !important",
        border: "none",
        margin: "0px",
        padding: "0px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    story_text: {
        fontSize: "13px",
        fontWeight: "normal",
        display: "inline",
        color: "#8b0000",
        padding: '5px',
        align: 'left',
    },

    dropdown_style: {
        autoWidth: true,
    },
}));

const Schema = Yup.object().shape({
    newPw: Yup.string().required("This field is required").min(6, 'Lösenordet måste vara minst 6 tecken långt.'),
    confirmPw: Yup.string().when("newPw", {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf(
            [Yup.ref("newPw")],
            "Skriv samma lösenord igen."
        )
    })
});

export const ChangePassword = props => {


    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [messageType, setMessageType] = React.useState('success');
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
        //event.preventDefault();
        let password = {
            oldPassword: event.oldPw,
            newPassword: event.newPw,
            isUpdateSuccessful: null
        };
        UserService.setPassword(password, location.state.currentUser.username)
            .then((string) => {
                setMessageType('success');
                handleSnackBar();
                //Wait a short while (so the user can see the message) and then return to the user settings screen.
                setTimeout(function(){
                    history.push({pathname: '/user_detail', state: { currentUser: location.state.currentUser}});
                }, 3000);
            })
            .catch((err) => {
                setMessageType('error');
                handleSnackBar();
                console.log("Error updating password: " + err);
            });
    };

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    /*
    const handleChange = (event) => {
        setPassword({
            ...password,
            [event.target.name]: event.target.value
        });
        //isValid.current = password.newPassword === password.newPassword2;
    };

    useLayoutEffect( () => {
        isValid.current = password.newPassword === password.newPassword2;
        console.log("isValid = " + isValid.current);
    }, [password]);
*/
    return (
        <div style={{ textAlign: "center" }} >
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <div>
                    <Alert onClose={handleSnackBarClose} severity={ messageType === "success" ? "info" : "error"}>
                        {messageType === 'success' ? "Lösenordet är uppdaterat" : "Misslyckades med att spara lösenordet!"}
                    </Alert>
                </div>
            </Snackbar>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1>Ändra lösenord</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push({pathname: '/user_detail', state: { currentUser: location.state.currentUser}})} />
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 25}}>
                        <h4>Uppdatera dina användar uppgifter eller ändra lösenord.</h4>
                    </Grid>
                    <Paper variant="outlined" sx={{border: "none !important"}} className={classes.paper_transparent}>
                        <Formik initialValues={{
                            oldPw: "",
                            newPw: "",
                            confirmPw: ""
                        }}
                            validationSchema={Schema}
                            onSubmit={(values) => { handleSubmit(values) }}
                        >
                            {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                                return (
                                    <form onSubmit={handleSubmit} className={classes.form_element}>
                                        <Grid item xs={12}>
                                            {/* <label htmlFor="password" className={classes.story_text}>Gamla lösenordet</label> */}
                                            <div className="form-field-label">Gamla lösenordet</div>
                                            <TextField
                                                fullWidth
                                                id="oldPw"
                                                name="oldPw"
                                                variant="outlined"
                                                type="password"
                                                value={values.oldPw}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <label htmlFor="password" className={classes.story_text}>Nytt lösenord</label> */}
                                            <div className="form-field-label">Nytt lösenord</div>
                                            <TextField
                                                fullWidth
                                                id="newPw"
                                                name="newPw"
                                                variant="outlined"
                                                type="password"
                                                value={values.newPw}
                                                onChange={handleChange} />
                                            <span className="error" style={{ color: "white" }}>
                                                {errors.newPw}
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <label htmlFor="password" className={classes.story_text}>Verifiera nytt lösenord</label> */}
                                            <div className="form-field-label">Verifiera nytt lösenord</div>
                                            <TextField
                                                fullWidth
                                                id="confirmPw"
                                                name="confirmPw"
                                                variant="outlined"
                                                type="password"
                                                value={values.confirmPw}
                                                onChange={handleChange} />
                                            <span className="error" style={{ color: "white" }}>
                                                {errors.confirmPw}
                                            </span>

                                        </Grid>
                                        <br></br> <br></br>
                                        <Grid item xs={12} style={{ marginTop: "6%" }}>
                                            <Button color="primary" variant="contained" type="submit">
                                                Spara
                                            </Button>
                                        </Grid>
                                      
                                    </form>
                                    
                                );
                            }}
                        </Formik>
                    </Paper>
                    
                </Grid>
            </div>
        </div>
    );
};