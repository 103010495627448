import Cookies from "universal-cookie";
import UserService from "./services/UserService";
import messaging from "@firebase/messaging";

class Auth {

    async login(username, password, cb, cf) {
        await fetch(process.env.REACT_APP_API_URL + "/authenticate", {
            method: "POST",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
            },
            credentials: 'include',
            body: JSON.stringify({ 'username': username, 'password': password })
        })
            .then(res => res.json())
            .then(data => {
                const cookies = new Cookies();
                cookies.set("token", data.token, { path: "/" });
                cookies.set("tokenAge", Date.now(), { path: "/" });
                cb();
            })
            .catch((error) => {
                console.log("Auth.js - Det gick fel!\n" + error.toString());
                cf();
            });
    }


    logout(cb) {
        //console.log("Auth.js - FCMToken on logout: " + messaging.getToken());
        UserService.removeFCMToken()
            .catch((err) => {
                console.log("Error removing FCMToken: " + err);
            });
        const cookies = new Cookies();
        cookies.remove("token");
        cookies.remove("tokenAge");
        console.log("Auth.js - logout - removed token.");
        cb();
    }

    isAuthenticated() {
        const cookies = new Cookies();
        const cookieIsSet =  cookies.get("token");
        const tokenBirth = Number.parseInt(cookies.get("tokenAge"));
        const currentTime = Date.now();
        console.log("Auth.js - Värdet av cookien 'token':\n" + cookieIsSet);
        if (cookieIsSet == null ||
            cookieIsSet === "undefined" ||
            tokenBirth == null ||
            Date.now() - tokenBirth >= ((1000*60*60*24*4)-(1000*60*60))) {
            console.log("Auth.js, isAuthenticated = false: The age of the token (ms):\n" + (currentTime - tokenBirth));
            return false;
        } else {
            console.log("Auth.js, isAuthenticated = true: The age of the token (ms):\n" + (currentTime - tokenBirth));
            return true;
        }
    }
}

export default new Auth();
