import {Redirect, Route} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import {ProtectedRoute} from "./protected.route";

export const ProtectedRefreshRoute = ({
                          component: Component,
                          redirectionPath,
                          ...rest
                      }) => {
    redirectionPath = redirectionPath ?? '/';
    const perf = performance.getEntriesByType('navigation')[0].toJSON();
    const reloadType = perf.type !== 'reload';

    const handler = useCallback((e) => {
        e.preventDefault();
        e.returnValue = '';
        return true;
    }, []);

    useEffect(() => {
        window.onbeforeunload = handler;

        return () => {
            window.onbeforeunload = handler;
        };
    });
    return (
        <>
            {reloadType ? (
                <ProtectedRoute component={Component} {...rest} />
            ) : (
                <Redirect to={redirectionPath} />
            )}
        </>
    );
};