import React, {useContext, useEffect} from "react";
import {Grid, Paper} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import clsx from "clsx";
import UserService from "../services/UserService";
import { makeStyles } from "@mui/styles";
import {useHistory, useLocation} from "react-router-dom";
import {AppHeader} from "../components/AppHeader";
import DeleteIcon from '@mui/icons-material/Delete';
import {forEach} from "core-js/stable/dom-collections";
import async from "async";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const useStyles = makeStyles((theme) => ({
    btn_fab: {
        width: "70px",
        height: "70px",
        background: "#EDEDED",
        margin: "20px",
        '& .MuiSvgIcon-root': {
            color: "#9bca86 !important",
            width: "62px",
            height: "62px",
        },
        '&:hover': {
            backgroundColor: "#f3f3f3",
        }
    },
    btn_fab_label: {
        color: "#ffffff",
        fontSize: 18,
        paddingLeft: 15,
    },
    paper_transparent: {
        display: 'flex',
        width: "100%",
        backgroundColor: "transparent !important",
        border: "none !important",
        margin: "0px",
        paddingBottom: "15px",
        justifyContent: 'center',
        alignItems: 'center',
    },

    form_contact_wrapper: {
        borderRadius: 14,
        border: "4px solid rgba(255,255,255,0.25)",
        height: 'auto',
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "12px !important",
        marginTop: "12px !important",
        marginRight: "auto",
        padding: "0.6rem",
        '& .MuiInputBase-formControl': {
            width: "100% !important",
        },
        '& .MuiButtonBase-root': {
            marginBottom: "5px !important",
        },
    },

    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
        marginBottom: 15,
        marginTop: "15px !important",
    },

    buttonSmall: {
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        textAlign: "left",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
    },


}))
export const Contacts = props => {

    const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []
    });
    const [messageType, setMessageType] = React.useState('success');
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [activeContact, setActiveContact] = React.useState(false);
    let [originalContact, setOriginalContact] = React.useState({});
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        console.log("selected user: " + JSON.stringify(location.state.currentUser));
        const getUser = async () => {
            let userissimo = {};
            userissimo = await UserService.getUser(location.state.currentUser.username);
            await setUser(userissimo);
            console.log("user after fetch and set:", user);
        };
        getUser().catch((err) => {
            console.log("Error fetching user." + err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContactSubmit = (event) => {
        event.preventDefault();
    };

    const handleEditContact = (contact) => {
        // Remove new contact entry
        const contacts = user.additionalContacts.filter((c) => c.name !== '');
        setUser({
            ...user,
            additionalContacts: contacts
        });

        // Set active contact to selected contact
        setActiveContact(contact);
        setOriginalContact(contact);
    };

    const handleSaveContact = (contact) => {
        console.log("saving contact:", activeContact);
        console.log("original contact:", originalContact);
        console.log("All contacts in user:", user.additionalContacts);
        //Sort out the current edited contact.
        const contacts = user.additionalContacts.filter((c) => {
            return !(c.name === originalContact.name && c.phone === originalContact.phone);
        });
        console.log("Other contacts found in user:", contacts);
        //Add the edited contact to the user.
        contacts.unshift(activeContact);

        contacts.sort((a,b) => (a.name > b.name) ? 1 : -1);
        //let updatedUser = user;
        let updatedUser = {
            ...user,
            additionalContacts: contacts
        };
        console.log("updatedUser:", updatedUser);
        setActiveContact(false);

        const updateContact = async () => {
            let userissimo = await UserService.updateUser(updatedUser, null);
            console.log("user as returned from updateUser service: ", userissimo);
            await setUser(userissimo);
            console.log("user after setting in update contact:", user);
        };
        updateContact(updatedUser).catch((err) => {
            console.log("Error updating contact.", err);
        });
    }

    const handleDeleteContact = (event) => {
        if (window.confirm("Are you sure to delete this contact: " + activeContact.name + "?")) {

            UserService.deleteContact(activeContact, location.state.currentUser.username)
                .then((string) => {
                    const contacts = user.additionalContacts.filter((c) => c.name !== activeContact.name);

                    setActiveContact(false);
                    setUser({
                        ...user,
                        additionalContacts: contacts
                    });

                    setMessageType('success');
                })
                .catch((err) => {
                    setMessageType('error');
                    console.log("Error deleting contact: " + err);
                });
        }};

    const handleAddContact = (event) => {

        const newContact = {
            name: '',
            phone: '',
            email: '',
            description: '',
        };
        var contacts = user.additionalContacts;
        contacts.unshift(newContact);

        setActiveContact(newContact);
        setUser({
            ...user,
            additionalContacts: contacts
        });
    };

    const handleContactChange = (event) => {
        setActiveContact({
            ...activeContact,
            [event.target.name.substring(7).toLowerCase()]: event.target.value
        });
    };

    const handleSaveNewContact = (event) => {
        UserService.addContact(activeContact, location.state.currentUser.username)
            .then((string) => {
                var contacts = user.additionalContacts.filter((c) => c.name !== '');
                contacts.unshift(activeContact)

                setUser({
                    ...user,
                    additionalContacts: contacts
                });
                setActiveContact(false);

                setMessageType('success');
            })
            .catch((err) => {
                setMessageType('error');
                console.log("Error deleting contact: " + err);
            });
    };

    const handleCancelContactForm = (event) => {
        // Remove new contact entry
        const contacts = user.additionalContacts.filter((c) => c.name !== '');
        setUser({
            ...user,
            additionalContacts: contacts
        });
        setActiveContact(false);
    };

    const handleClick = (url)=>{
        window.open(url);
    }

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader/>
            <div className="content-pane content-pane-green">
            <Grid item xs={12} className="title_heading" style={{marginTop:50}}>
                <h1 style={{marginBottom:0}}>Kontakter</h1>
            </Grid>
            <Grid item xs={12} style={{textAlign: "left", paddingLeft: "15px"}}>
                <h4>Personer som bor eller befinner sig nära den äldre.</h4>
            </Grid>
            <Grid item xs={12} className={classes.addContainer}>
                <Fab className={classes.btn_fab} onClick={handleAddContact}>
                    <AddIcon color="primary"/>
                </Fab>
                <span className={classes.btn_fab_label}>Lägg till kontakt</span>
            </Grid>
            <Paper variant="outlined" className={classes.paper_transparent}>
                <form onSubmit={handleContactSubmit} id="updateUserContactsForm">
                    {user.additionalContacts ?
                        user.additionalContacts.map((contact) => (
                            (contact.email === activeContact.email || contact.name === '' ?
                                    <Grid item xs={12} className={classes.form_contact_wrapper}>
                                        <TextField
                                            fullWidth
                                            id="contactName"
                                            name="contactName"
                                            variant="outlined"
                                            placeholder="Namn"
                                            defaultValue={contact.name}
                                            onChange={handleContactChange}
                                        />
                                        <TextField
                                            fullWidth
                                            id="contactDescription"
                                            name="contactDescription"
                                            variant="outlined"
                                            placeholder="Beskrivning"
                                            defaultValue={contact.description}
                                            onChange={handleContactChange}
                                        />
                                        <TextField
                                            fullWidth
                                            id="contactPhone"
                                            name="contactPhone"
                                            variant="outlined"
                                            placeholder="Mobil"
                                            defaultValue={contact.phone}
                                            onChange={handleContactChange}
                                        />
                                        <Grid container style={{justifyContent: "space-between"}}>
                                            {contact.name !== '' ?
                                                <Grid item xs={6} style={{width:"48.5%", maxWidth:"48.5%"}}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="secondary"
                                                        className={clsx([classes.button_danger, classes.button_small])}
                                                        onClick={handleSaveContact}
                                                    >
                                                        Spara
                                                    </Button>
                                                </Grid> :
                                                <Grid item xs={6} style={{width:"48.5%", maxWidth:"48.5%"}}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button_small}
                                                        onClick={handleSaveNewContact}
                                                    >
                                                        Spara
                                                    </Button>
                                                </Grid>
                                            }
                                            <Grid item xs={6} style={{width:"48.5%", maxWidth:"48.5%"}}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    className={classes.button_small}
                                                    onClick={handleCancelContactForm}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> :
                                    <Grid item xs={12} className={classes.contact_person} onClick={() => handleEditContact(contact)}>
                                        <span style={{display: "table-cell", whiteSpace: "nowrap"}} >
                                            <div>
                                                {contact.name}
                                            </div>
                                            <div className="contact-name"><small><i>{contact.description}</i></small></div>
                                            <div>
                                                {contact.phone}
                                            </div>
                                        </span>
                                        <span style={{display: "table-cell", width: "100%", textAlign: "right"}}>
                                            <div><DeleteIcon style={{fontSize: "30px", color: "#aa3333", marginBottom: "15px"}} onClick={handleDeleteContact}/></div>
                                            <div>
                                                    <Button onClick={() => {handleClick("tel:" + contact.phone)}}
                                                            className={classes.buttonSmall}
                                                            variant="contained">
                                                            <LocalPhoneIcon fontSize="large"/>
                                                        </Button>
                                            </div>
                                        </span>
                                    </Grid>
                            )
                        )) : <div />}
                </form>
            </Paper>
            </div>
        </div>
    );
};