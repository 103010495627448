import React, {useState, useContext, useEffect} from "react";
import Auth from "../Auth";
import { Formik, Form, useField } from "formik";
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import UserService from "../services/UserService";
import { LoginContext } from "../contexts/LoginContext";
import Cookies from "universal-cookie";
import {useHistory, useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    form_field: {
        color: "#92959e",
        lineHeight: "1",
        fontFamily: 'HurmeDesign',
        fontSize: "16px",
        fontWeight: "normal",
        borderRadius: 14,
        border: "none",
        height: '60px',
        width: "100%",
        textAlign: "left",
        paddingTop: "4px",
        paddingLeft: "25px",
        marginBottom: "10px",
        backgroundImage: "none !important",
        boxShadow: "0px 4px 0px 0px #89b177 inset",
        outline: "none",
    },
    button_login: {
        paddingTop: "45px !important",
        paddingBottom: "45px !important",
        maxWidth: 414,
    },
    gradient_box: {
        height: "50vh",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        clipPath: "ellipse(110% 100% at 50% 0%)",
    },
    logo_wrapper: {
        width: "100%",
        maxWidth: 414,
        marginBottom: "2rem",
        '& img': {
            minHeight: 30, 
            maxHeight: 150, 
            maxWidth: "60%",
            // -webkit-filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
            filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
        }
    },
    input_wrapper: {
        width: "100%",
        maxWidth: 414,
    },
    swoosh: {
        background: "linear-gradient(to top right, #a2cf98, white)",
        marginLeft: "8%",
        alignItems: "left",
        borderTopLeftRadius: "4em",
        borderBottomLeftRadius: "4em",
        borderBottomRightRadius: "4em",
        paddingTop: "25px",
        paddingLeft: "25px",
        paddingRight: "15px",
        paddingBottom: "45px",
        textAlign: "left",
        color: "black",
        boxShadow: "-3px -3px 10px #aaaaaa, 5px 5px 10px #aaaaaa"
    },
}));

const MyTextInput = ({ label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input
                className={classes.form_field}
                {...field}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

export const Login = props => {
    const classes = useStyles();
    const [hasEnteredWrong, setHasEnteredWrong] = useState(false);
    const {setUser} = useContext(LoginContext);
    const location = useLocation();
    const history = useHistory();

   useEffect(() => {
       console.log("Checking if user is logged in.");
       if (Auth.isAuthenticated()) getUser();
    }, []);

    const getUser = async () => {
        const tmpUser = await UserService.getUser();
        setUser(tmpUser);
        /**
         if (tmpUser.confirmed === true) {
                console.log("Login.js - User is confirmed: " + tmpUser.confirmed);
                props.history.push("/home");
            } else {
                console.log("Login.js - User is NOT confirmed: " + tmpUser.confirmed);
                props.history.push("/confirm_login");
            }
         */
        props.history.push("/home");
    }

    const handleSubmit = () => {
        getUser();
    };

    return (
        <div>
            <div className="">
                <Formik
                    initialValues={{
                        username: "",
                        password: ""
                    }}
                    onSubmit={(values, actions) => {
                        Auth.login(values.username, values.password, () => {
                            actions.resetForm();
                            setHasEnteredWrong(false);
                            handleSubmit();
                        }, () => setHasEnteredWrong(true));
                    }}
                >
                    <section className="assymetric-lp">
                        <Form>
                            <div className={classes.gradient_box}>
                                <div className={classes.logo_wrapper}>
                                    <img style={{ minHeight: 30, maxHeight: 150, maxWidth: "50vw" }} src="/images/CurosenseLogo-w.svg" alt="Curosense Logo" className={classes.logo} />
                                </div>

                                <div className={classes.input_wrapper}>
                                    <MyTextInput
                                        name="username"
                                        type="text"
                                        autoCapitalize="off"
                                        placeholder="Användare"
                                    />
                                    <MyTextInput
                                        name="password"
                                        type="password"
                                        placeholder="Lösenord"
                                    />
                                </div>
                            </div>

                            {hasEnteredWrong ? <p style={{ color: "red" }}>Felaktiga inloggningsuppgifter </p> : ""}
                            <br></br>
                            <Button variant="contained" type="submit" color="primary" className={classes.button_login}>
                                Logga in
                            </Button>
                        </Form>
                    </section>
                </Formik>
            </div>
        </div>
    );
};