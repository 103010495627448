import Cookies from "universal-cookie";

class DeviceService {
    async getHealthStatus(devId, tenantUser) {
        const cookies = new Cookies();
        let uri = devId ? "/api/device/healthstatus/" + devId : "/api/device/healthstatus";
        let healthStatus = [];
        console.log("getHealthStatus URI: " + uri);
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getHealthStatus." + JSON.stringify(data));
                healthStatus = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return healthStatus;
    }
    async sendDeviceCommand(values, tenantUser) {
        const cookies = new Cookies();
        let uri = tenantUser ? "/api/device/command" + tenantUser : "/api/device/command/{tenantuser}";
        let result = "";
        console.log("sendCommand");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(values)
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from sendCommand." + data.toString());
                result = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return result;
    }

    async saveDeviceHomeLocation(deviceHomeLocation){
        const cookies = new Cookies();
        let uri = "/api/device/homelocation/" + deviceHomeLocation.devId;
        let result = {};
        console.log("saveDeviceHomeLocation \nURI: " + uri +
            "\nData: " + JSON.stringify(deviceHomeLocation));
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(deviceHomeLocation)
        })
            .then(res => res.json() )
            .then(data => {
                console.log("return from sendCommand." + JSON.stringify(data));
                result = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return result;

    }

    async getDeviceHomeLocation(devId, tenantUser) {
        const cookies = new Cookies();
        let uri = "/api/device/homelocation/" + devId;
        let homeLocation = {};
        console.log("getHomelocation");
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then((res) => res.json())
            .then(data => {
                console.log("return from getHomelocation." + JSON.stringify(data));
                homeLocation = data;
            })
            .catch((error) => {
                console.log("statusCode: " + error.status);
                if (error.status === "404") {
                    homeLocation = {
                        devId: "",
                        homeAddress: "",
                        coordinates: {},
                        geoPerimeter: 0,
                        geoFenceEnabled: false
                    };
                }
                console.log("Det gick fel! " + error.status);
            });
        console.log("homeLocation: " + JSON.stringify(homeLocation));
        return homeLocation;
    }

    async saveDevice(device){
        const cookies = new Cookies();
        let uri = "/api/device";
        let result = {};
        console.log("saveDevice \nURI: " + uri +
            "\nData: \n\t* devId: " + device.devId +
            "\n\t* name: " + device.name);
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include',
            body: JSON.stringify(device)
        })
            .then(res => res.json() )
            .then(data => {
                console.log("return from sendCommand." + JSON.stringify(data.toString()));
                result = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error);
            });
        return result;
    }

    async refrashHealthStatus(devId, tenantUser) {
        const cookies = new Cookies();
        let uri = "/api/device/refreshhealthstatus/" + devId;
        let response = {};
        console.log("refreshHealthStatus: " + process.env.REACT_APP_API_URL + uri);
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then((res) => res.status)
            .then(data => {
                console.log("return from getHomelocation." + data.toString());
                response = data;
            })
            .catch((error) => {
                console.log("Det gick fel! " + error.toString());
            });
        console.log("refreshHealthStatus: " + response.toString());
        return response;
    }

}

export default new DeviceService();