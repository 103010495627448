import React, {useContext, useEffect} from "react";
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UserService from "../services/UserService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { makeStyles } from '@mui/styles';
import Fab from "@mui/material/Fab";
import Auth from "../Auth";
import {LoginContext} from "../contexts/LoginContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    addContainer: {
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "20px",
        paddingTop: "20px",
        paddingLeft: "15px",
    },

    btn_fab: {
        width: "70px",
        height: "70px",
        background: "#EDEDED",
        margin: "20px",
        '& .MuiSvgIcon-root': {
            color: "#9bca86 !important",
            width: "62px",
            height: "62px",
        },
        '&:hover': {
            backgroundColor: "#f3f3f3",
        }
    },
    btn_fab_label: {
        color: "#ffffff",
        fontSize: 18,
        paddingLeft: 15,
    },

    paper: {
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
        margin: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
    },

    form_container: {
        padding: "10px",
    },

    paper_transparent: {
        display: 'flex',
        width: "100%",
        backgroundColor: "transparent !important",
        border: "none !important",
        margin: "0px",
        paddingBottom: "15px",
        justifyContent: 'center',
        alignItems: 'center',
    },

    button_danger: {},
    button_small: {},

    story_text: {
        fontSize: "13px",
        fontWeight: "normal",
        display: "inline",
        color: "#8b0000",
        padding: '5px',
        align: 'left',
    },

    static_text: {
        color: "#ffffff",
        fontSize: 16,
        borderRadius: 14,
        border: "4px solid #b3d7a3",
        height: '64px',
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "17px 18px",
    },

    dropdown_style: {
        autoWidth: true,
    },

    form_contact_wrapper: {
        borderRadius: 14,
        border: "4px solid rgba(255,255,255,0.25)",
        height: 'auto',
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "12px !important",
        marginTop: "12px !important",
        marginRight: "auto",
        padding: "0.6rem",
        '& .MuiInputBase-formControl': {
            width: "100% !important",
        },
        '& .MuiButtonBase-root': {
            marginBottom: "5px !important",
        },
    },

    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        padding: 15,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
        marginBottom: 15,
        marginTop: "15px !important",
    },
}));



export const UserDetail = props => {

    const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []
    });
    const [messageType, setMessageType] = React.useState('success');
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

//    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const {getLoggedInUser} = useContext(LoginContext);


    console.log("history", history);
    console.log("classes", classes);


    useEffect(() => {
        console.log("selected user: " + location.state.currentUser);
        const getUser = async () => {
            let userissimo = {};
            userissimo = await UserService.getUser(location.state.currentUser.username);
            setUser(userissimo);
            console.log("userissimo", userissimo);
        };
        getUser().catch((err) => {
            console.log("Error fetching user." + err);
        });
        console.log("The User after fetch: \n" + JSON.stringify(user));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        UserService.updateUser(user, location.state.currentUser.username)
            .then((string) => {
                setMessageType('success');
                handleSnackBar();
            })
            .catch((err) => {
                setMessageType('error');
                handleSnackBar();
                console.log("Error updating user details: " + err);
            });
    };

    const handleContactSubmit = (event) => {
        event.preventDefault();
    };

    const handleSnackBar = () => {
        setOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChangePassword = () => {
        history.push({pathname: '/change_password', state: { currentUser: location.state.currentUser}});
    }

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    };

    const handleLogout = () => {
        Auth.logout(() => { });
        history.push("/");
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                <div>
                    <Alert onClose={handleSnackBarClose} severity="success">
                        {messageType === 'success' ? "Sparat" : "Misslyckades med att spara!"}
                    </Alert>
                </div>
            </Snackbar>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{marginBottom:0}}>Mina sidor</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push({pathname: '/home', state: { currentUser: location.state.currentUser}})} />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: "left", marginBottom: 15}}>
                        <h4>Uppdatera dina användaruppgifter eller ändra lösenord.</h4>
                    </Grid>
                    <Paper variant="outlined" className={classes.paper_transparent}>
                        <form onSubmit={handleSubmit} id="updateUserDetailsForm">
                            <Grid item xs={12}>
                                <div className="form-field-label">Namn</div>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    variant="outlined"
                                    value={user.name}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Adress</div>
                                <TextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    variant="outlined"
                                    value={user.address}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Postnummer</div>
                                <TextField
                                    fullWidth
                                    id="zip"
                                    name="zip"
                                    variant="outlined"
                                    value={user.zip}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Ort</div>
                                <TextField
                                    fullWidth
                                    id="postalAddress"
                                    name="postalAddress"
                                    variant="outlined"
                                    value={user.postalAddress}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Mobil</div>
                                <TextField
                                    fullWidth
                                    id="mobilePhone"
                                    name="mobilePhone"
                                    variant="outlined"
                                    value={user.mobilePhone}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">E-post</div>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    value={user.email}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="form-field-label">Larm via</div>
                                <Select
                                    sx={{
                                        boxShadow: "none",

                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                    }}
                                    id="messageChannel"
                                    name="messageChannel"
                                    fullWidth={true}
                                    variant={"standard"}
                                    disableUnderline
                                    className={classes.form_field_time}
                                    value={user.messageChannel}
                                    onChange={handleChange}>
                                        <MenuItem value={1}
                                                  sx={{display: "flex",
                                                        justifyContent: "center",
                                                        fontFamily: "HurmeDesign",
                                                        fontSize: "16px",
                                                        color: "#92959e"}}
                                                  divider={true}
                                                  key={'SMS'}>SMS</MenuItem>
                                        <MenuItem value={2}
                                                  sx={{display: "flex",
                                                        justifyContent: "center",
                                                        fontFamily: "HurmeDesign",
                                                        fontSize: "16px",
                                                        color: "#92959e"}}
                                                  divider={true}
                                                  key={'Notiser'}>notiser (mobilapp)</MenuItem>
                                </Select>
                            </Grid>
                        </form>
                    </Paper>
                    <Grid item xs={12} style={{marginTop:35}}>
                        {/* <Button color="error" variant="contained" onClick={() => handleChangePassword()}>
                            Ändra lösenord
                        </Button> */}
                        <Button
                            style={{ marginTop: "6%" }}
                            onClick={() => handleChangePassword()}
                            variant="contained"
                            color="secondary"
                            className={classes.button_danger}>
                            Ändra lösenord
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" type="submit" form="updateUserDetailsForm">
                            Spara
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => handleLogout()} className={classes.button_small}>
                            Logga ut
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};