import './App.css';
import React, {useState, useRef, useEffect} from 'react';
import {Route, Switch, useHistory} from "react-router-dom";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Rules } from "./pages/Rules";
import { RuleDetail } from "./pages/RuleDetail";
import { Alarms } from "./pages/Alarms";
import { AlarmDetail } from "./pages/AlarmDetail";
import { Events } from "./pages/Events";
import { EventDetail } from "./pages/EventDetail";
import { Devices } from "./pages/Devices";
import { ErrorPage } from "./pages/ErrorPage";
import { ProtectedRefreshRoute } from "./ProtectedRefreshRoute";
import { UserDetail } from "./pages/UserDetail";
import { ChangePassword } from "./pages/ChangePassword";
import { ConfirmLogin } from "./pages/ConfirmLogin";
import { EventList } from "./pages/EventList";
import { EventListInduo } from "./pages/EventListInduo";
import { LoginContext } from "./contexts/LoginContext";
import { BatteryInfo } from "./pages/BatteryInfo";
import { ContactInfo } from "./pages/ContactInfo";
import { Contacts } from "./pages/Contacts";
import { Settings } from "./pages/Settings";
import {HealthAndLocation} from "./pages/HealthAndLocation";
import {DeviceDetail} from "./pages/DeviceDetail";
import {onMessageListener} from "./firebase";
import {Alert} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";


function App() {

  const [user, setUser] = useState("");
  // State for on app notification.
  const [notificationState, setNotificationState] = useState({
    open: false,
    message: {
      title: "",
      body: ""
    },
    severity: "info"
  });
/*
  let notificationType = "info";
  console.log("notificationType at top level: " + notificationType);
*/

  const {
    open,
    message
  } = notificationState;

  const handleNotificationClick = () => {
    // navigate("/journal");
    setNotificationState({
      ...notificationState,
      open: false
    });
  };

  const handleNotificationClose = () => {
    setNotificationState({
      ...notificationState,
      open: false
    });
  };

  /**
   * Method that listens to firebase FCM notification.
   * Messages that are sent when the app is in use
   * trigger this method.
   */
  onMessageListener()
      .then((payload) => {
            console.log("got message! title: " + JSON.stringify(payload.data.title) + ",\n " +
                "body: " + JSON.stringify(payload.data.body) + ",\n" +
                "messageType: " + JSON.stringify(payload.data.messageType));
            let notificationType = "";
            switch (payload.data.messageType) {
              case "info": { notificationType = "info"; break; }
              case "Alarm": { notificationType = "error"; break; }
              case "warning": { notificationType = "warning"; break; }
              case "Success": { notificationType = "success"; break; }
              case "healthdata-updated": { notificationType = "info"; break;}
              default: { notificationType = "info"; }
            }
            console.log("notificationType after switch: " + notificationType);
            setNotificationState({
              open: true,
              message: {
                title: payload.data.title,
                body: payload.data.body
              },
              severity: notificationType
            });
          }
      )
      .catch((err) => {
        console.log(`An error occured when showing notification ${err}`);
      });

    return (
      <div className="App">
        <LoginContext.Provider value={{ user, setUser }}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose}>
            <Alert
                onClose={handleNotificationClose}
                severity={notificationState.severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
              <b><big>{notificationState.message.title}</big></b><br/>{notificationState.message.body}
            </Alert>
          </Snackbar>
            <Switch>
              <Route exact path="/" component={Login}/>
              <ProtectedRefreshRoute path="/home" redirectionPath="/" component={Home} />
              <ProtectedRefreshRoute path="/rules" redirectionPath="/" component={Rules} />
              <ProtectedRefreshRoute path="/rule_detail" redirectionPath="/" children={<RuleDetail />} />
              <ProtectedRefreshRoute path="/alarms" redirectionPath="/" component={Alarms} />
              <ProtectedRefreshRoute path="/alarm_detail" redirectionPath="/" children={<AlarmDetail />} />
              <ProtectedRefreshRoute path="/events" redirectionPath="/" component={Events} />
              <ProtectedRefreshRoute path="/event_detail/:eventId" redirectionPath="/" children={<EventDetail />} />
              <ProtectedRefreshRoute path="/devices" redirectionPath="/" component={Devices} />
              <ProtectedRefreshRoute path="/user_detail" redirectionPath="/" component={UserDetail} />
              <ProtectedRefreshRoute path="/change_password" redirectionPath="/" component={ChangePassword} />
              <ProtectedRefreshRoute path="/confirm_login" redirectionPath="/" component={ConfirmLogin} />
              <ProtectedRefreshRoute path="/event_list" redirectionPath="/" component={EventList} />
              <ProtectedRefreshRoute path="/event_list_induo" redirectionPath="/" component={EventListInduo} />
              { /** <ProtectedRoute path="/event_list" component={EventListNew} /> */}
              <ProtectedRefreshRoute path="/battery_info" redirectionPath="/" component={BatteryInfo} />
              { /** <ProtectedRefreshRoute path="/contact_info" redirectionPath="/" component={ContactInfo} /> */}
              <ProtectedRefreshRoute path="/contacts" redirectionPath="/" component={Contacts} />
              <ProtectedRefreshRoute path="/health_location" redirectionPath="/" component={HealthAndLocation} />
              <ProtectedRefreshRoute path="/device_detail" redirectionPath="/" component={DeviceDetail} />
              <ProtectedRefreshRoute path="/settings" redirectionPath="/" component={Settings} />
              <Route path="*" component={ErrorPage} />
            </Switch>
        </LoginContext.Provider>
      </div>
  );
}

export default App;
