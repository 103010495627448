import Cookies from "universal-cookie";

class AlarmService {

    async getAlarms(tenantUser){
        const cookies = new Cookies();
        let alarma = [];
        console.log("getAlarms");
        let uri = tenantUser ? "/api/alarms/" + tenantUser : "/api/alarms";
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getAlarm." + data);
                alarma = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return alarma;
    }

    async getThreeAlarms(){
        const cookies = new Cookies();
        let alarma = [];
        console.log("getAlarms");
        await fetch(process.env.REACT_APP_API_URL + "/api/alarm", {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                alarma = data.slice(0,3);
                console.log("return from getThreeAlarms:" + JSON.stringify(alarma));
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return alarma;
    }

    async getAlarm(alarmId, tenantUser){
        const cookies = new Cookies();
        let alarm = '';
        console.log("getAlarm: " + alarmId);
        let uri = tenantUser ? "/api/alarm/" + alarmId + "/" + tenantUser : "/api/alarm/" + alarmId;
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from getRules." + data);
                alarm = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });
        return alarm;
    }

    async deleteAlarmGetThree(alarmId, tenantUser) {
        const cookies = new Cookies();
        let alarma = [];
        let uri = tenantUser ? "/api/alarm/isignored/" + alarmId + "/" + tenantUser : "/api/alarm/isignored/" + alarmId;
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from deleteAlarmGetThree." + data);
                alarma = data.slice(0,3);
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });

        return alarma;
    }

    async deleteAlarm(alarmId, tenantUser) {
        const cookies = new Cookies();
        let alarma = [];
        let uri = tenantUser ? "/api/alarm/isignored/" + alarmId + "/" + tenantUser : "/api/alarm/isignored/" + alarmId;
        await fetch(process.env.REACT_APP_API_URL + uri, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                Authorization: "Bearer " + cookies.get("token"),
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log("return from deleteAlarm." + data);
                alarma = data;
            })
            .catch((error) => {
                console.log("Det gick fel!" + error.toString());
            });

        return alarma;
    }


    /*
    createBody (values) {
        const startTime = values.start_hour + ":" + values.start_minute + ":00";
        const endTime = values.end_hour + ":" + values.end_minute + ":00";
        //TODO lägg till detta fält i formuläret.
        //const isActive = values.is_active;
        const dayOfWeek =
            values.mon === true ? "Mon," : "" +
            values.tue === true ? "Tue," : "" +
            values.wed === true ? "Wed," : "" +
            values.thu === true ? "Thu," : "" +
            values.fri === true ? "Fri," : "" +
            values.sat === true ? "Sat," : "" +
            values.sun === true ? "Sun" : "";

        const jsonString = JSON.stringify({'ruleName': values.rule_name,
            'startTime': startTime,
            'endTime': endTime,
            'ruleString': (values.rule_type === "1" ? "inactive" : "active"),
            'active': true,
            'dayOfWeek': "Mon,Tue"});
        console.log('the json string: ' + jsonString);
        return jsonString;
    }
*/
}

export default new AlarmService();

