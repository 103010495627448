import {Grid, Paper} from "@mui/material";
import React from "react";
import {useState} from "react";
import {makeStyles} from "@mui/styles";
import {AppHeader} from "../components/AppHeader";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    contentPane: {
        display: "flex",
        width: "calc(100% - 30px)"
    },

    contact_person: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        padding: 10,
        marginBottom: 10,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    contact_description: {
        fontSize: 15,
        fontStyle: "italic",
    },

    contact_text: {
        fontSize: 15,
    },

    buttonSmall: {
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        textAlign: "left",
        justifyContent: "center",
        alignItems: "center",
        fullWidth: "true",
    },

    contactButtons: {
        justifyContent: "right",
        alignItems: "flex-end",
    }
}));

export const ContactInfo = props => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useState(location.state.currentUser);

    const handleClick = (url)=>{
        window.open(url);
    }

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{marginBottom:0}}>Kontakter</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.goBack()} />
                    </Grid>
                    <Grid className={classes.contentPane} item xs={12} style={{textAlign: "left"}}>
                        <h4>Personer som bor eller befinner sig nära den äldre.</h4>
                    </Grid>

                        {user.additionalContacts ?
                            user.additionalContacts.map((contact) => (
                                <Grid sx={{width: "calc(100% -30px)", marginTop: "15px", marginLeft: "15px", marginBottom: "15px"}} item xs={12} className={classes.contact_person}>
                                    <Grid container direction={"row"}>
                                        <Grid item xs={8}>
                                            <div>{contact.name}</div>
                                            <div className={classes.contact_description}>{contact.description}</div>
                                            <div className={classes.contact_text}><small>tfn:</small><br/>{contact.phone}</div>
                                            <div className={classes.contact_text}><small>epost: </small><br/>{contact.email}</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container direction={"column"} className={classes.contactButtons}>
                                                <Grid item xs={12}>
                                                    <Button onClick={() => {handleClick("tel:" + contact.phone)}}
                                                            className={classes.buttonSmall}
                                                            variant="contained">
                                                        <LocalPhoneIcon fontSize="large"/>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button onClick={() => {handleClick("mailto:" + contact.email)}}
                                                            className={classes.buttonSmall}
                                                            variant="contained">
                                                        <EmailIcon fontSize="large"/>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                )
                            ) : <div />}
                </Grid>
            </div>
        </div>
    );
}

