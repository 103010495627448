import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";
import UserService from "../services/UserService";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import InsightsIcon from '@mui/icons-material/Insights';
import UpdateIcon from '@mui/icons-material/Update';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import PlaceIcon from '@mui/icons-material/Place';
import {BatteryStatus} from './BatteryStatus';
import {DeviceHomeLocation} from "./DeviceHomeLocation";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },

    gradient_box_green: {
        display: "flex",
        height: "300px",
        paddingTop: "60px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "5rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "linear-gradient(90deg, rgba(169,209,154,1) 0%, rgba(164,207,146,1) 100%)",
        clipPath: "ellipse(80% 100% at 50% 0%)",
        '@media (max-width: 320px)': {
            height: "280px",
        },
    },

    gradient_box_red: {
        display: "flex",
        height: "300px",
        paddingTop: "60px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "5rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "linear-gradient(90deg, rgba(255,77,87,1) 0%, rgba(249,28,41,1) 100%)",
        clipPath: "ellipse(80% 100% at 50% 0%)",
        '@media (max-width: 320px)': {
            height: "280px",
        },
    },

    logo_wrapper: {
        width: "100%",
        maxWidth: 414,
        '& img': {
            minHeight: 30,
            maxHeight: 150,
            maxWidth: "55%",
            // -webkit-filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
            filter: "drop-shadow( 0px 0px 20px rgba(255, 255, 255, .7))",
        }
    },

    button: {
        display: "flex",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        paddingLeft: "30px",
        textAlign: "left",
        justifyContent: "flex-start",
        alignItems: "end",
        border: "1px",
        borderColor: theme.palette.greyScale.dark,
    },

    buttonSmall: {
        display: "flex",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        textAlign: "left",
        justifyContent: "center",
        alignItems: "center",
        fullWidth: "true",
        background: "linear-gradient(0deg, rgba(220,220,220,1) 0%, rgba(240,240,240,1) 100%)",
        maxHeight: "50px",
    },

    buttonContainer: {
        display: "flex",
        maxWidth: "400px",
    },

    buttonIcon: {
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "10px",
    },

    buttonAlarmGreen: {
        display: "flex",
        paddingTop: "15px !important",
        paddingBottom: "15px !important",
        //paddingLeft: "30px",
        textAlign: "left",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },

    buttonAlarmRed: {
        display: "flex",
        paddingTop: "15px !important",
        paddingBottom: "15px !important",
        //paddingLeft: "20px",
        textAlign: "left",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        background: "linear-gradient(180deg, rgba(255,77,87,1) 0%, rgba(243,22,35,1) 100%)",
    },

    buttonLabel: {
        marginLeft: "10px",
    },

    buttonLabelSmall: {
        marginLeft: "5px",
        alignItems: "center",
        color: "#575757",
        maxHeight: "40px",
    },

    labelDescription: {
        display: "block",
        fontSize: "14px",
        marginTop: "5px",
    },

    curolaContainer: {
        width: "100%",
        position: "absolute",
        top: "150px",
    },

    curola: {
        width: "50%",
        maxWidth: "190px",
        height: "auto",
    },

    batteryNormal: {
        color: "#575757",
    },

    batteryWarning: {
        color: "#DDAA00",
        textShadow: "inherit",
    },

    batteryCritical: {
        color: "#FF0000",
    }
}));

export const HomeSingleUserButtons = props => {

    const classes = useStyles();
    const history = useHistory();

    console.log("HomeSingleUserButtons - User devices: " + JSON.stringify(props.user.devices));
    const handleLinkClick = (componentName) => {
        console.log("clicked: " + componentName);
        history.push("/" + componentName);
    };

    const getHealthDevice = () => {
        let device = props.user.devices.filter((device) => device.family === "reachfar");
        return device;
    }

    const getPositionDevice = () => {
        let device = props.user.devices.filter((device) => device.family === "tretyTM4");
        return device;
    }

    const getResidentialDevice = () => {
        let device = props.user.devices.filter((device) => device.family === "wolf");
        return device;
    }


/**    const checkClassForBattery = () => {
        if (parseInt(UserService.checkBatteryStatus(props.user), 10) < 10) {
            console.log("battery status was: < 10");
            return classes.batteryCritical;
        } else if (parseInt(UserService.checkBatteryStatus(props.user), 10) < 30) {
            console.log("battery status was: < 30");
            return classes.batteryWarning;
        } else {
            console.log("battery status was normal");
            return classes.batteryNormal;
        }
    }

    const batteryClassName = checkClassForBattery();
    console.log("Battery ClassName: " + batteryClassName);
*/
    const hasAlarm = props.user.hasAlarm;

    return (
        <Grid container direction={"row"} className={classes.buttonContainer}>
            <Grid item xs={12}>
                    <Button
                        onClick={() => history.push({pathname: '/alarms', state: { currentUser: props.user}})}
                        variant="contained"
                        color="secondary"
                        className={hasAlarm ? classes.buttonAlarmRed : classes.buttonAlarmGreen}>
                        <div className={classes.buttonIcon}>
                            <img alt="Ikon Alarm Röd" src={hasAlarm ? "/images/icon-alarm2.svg" : "/images/icon-alarm.svg"}
                                 width={hasAlarm ? "60" : "50"} />
                        </div>
                        <label className={classes.buttonLabel}>
                            <span className={classes.labelDescription} style={{marginTop: 0}}>
                                { hasAlarm ?
                                <>Det finns olästa larm. Gå till larmsidan för att få mer information.</> :
                                <>Allt är som det ska!</> }
                            </span>
                        </label>
                    </Button>
            </Grid>
            { getResidentialDevice().length > 0 ?
                <Grid item xs={12}>
                    <Button
                        onClick={() => history.push({pathname: '/rules', state: { currentUser: props.user}})}
                        className={classes.button}
                        variant="outlined"
                        sx={{ borderColor: 'greyScale.dark'}}>
                        <UpdateIcon/>
                        <label className={classes.buttonLabel}>Mina regler</label>
                    </Button>
                </Grid> : <div /> }
            { (getHealthDevice().length > 0) || (getPositionDevice().length > 0) ?
                <Grid item xs={12}>
                    <Button
                        onClick={() => history.push({pathname: '/health_location', state: { devices: props.user.devices}})}
                        className={classes.button}
                        variant="outlined">
                        <HealthAndSafetyIcon/>
                        {getHealthDevice().length > 0 ?
                            <label className={classes.buttonLabel}>Hälsa / position</label> :
                            <label className={classes.buttonLabel}>Position</label>
                        }
                    </Button>
                </Grid> : <div />}
            {/**}
                <Grid item xs={12}>
                    <Button
                        onClick={() => history.push({pathname: '/event_list', state: { currentUser: props.user}})}
                        className={classes.button}
                        variant="outlined">
                        <InsightsIcon/>
                        <label className={classes.buttonLabel}>Händelser</label>
                    </Button>
                </Grid>
            {*/}
            <Grid item xs={4}>
                <Button
                    onClick={() => history.push({pathname: '/battery_info', state: { devices: props.user.devices}})}
                    className={classes.buttonSmall}
                    variant="outlined">
                    <label><BatteryStatus devices={props.user.devices} iconOnly={true}/></label>
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={() => history.push({pathname: '/contacts', state: { currentUser: props.user}})}
                    className={classes.buttonSmall}
                    variant="outlined">
                    <PhoneIcon fontSize="large" className={classes.buttonLabelSmall}/>
                    <label className={classes.buttonLabelSmall}></label>
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={() => history.push({pathname: '/settings', state: { currentUser: props.user }})}
                    className={classes.buttonSmall}
                    variant="outlined">
                    <SettingsIcon fontSize="large" className={classes.buttonLabelSmall}/>
                    <label className={classes.buttonLabelSmall}></label>
                </Button>
            </Grid>
        </Grid>
    );
}