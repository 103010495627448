import React, { useEffect } from 'react';
import { AppHeader } from "../components/AppHeader";
import { Grid, Paper } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Divider, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/material/Icon';
import EventService from "../services/EventService";
import TimeHelperService from "../services/TimeHelperService";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: "100%",
        backgroundColor: theme.palette.greyScale.blueish,
        boxShadow: "5px 5px 10px #aaaaaa",
        padding: "5px",
    },
    paper_grey: {
        color: "#ffffff",
        fontSize: 16,
        lineHeight: "1.2",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        border: "1px solid #fff",
        borderTopWidth: 2.5,
        borderRadius: 14,
        width: "calc(100% - 30px)",
        margin: "15px auto",
        padding: 15,
        boxShadow: "0px 5px 0px 0px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%)",
    },

    message_cell: {
        fontSize: 16,
        lineHeight: "1.2",
        minHeight: 30,
        textAlign: 'left',
        letterSpacing: 0,
        paddingLeft: 15,
        display: "flex",
        alignItems: "center",
        '&:hover': {
            cursor: "pointer",
        }
    },

    form_field_time: {
        color: "#ffffff",
        lineHeight: "1",
        fontFamily: 'HurmeDesign',
        fontSize: "17px",
        fontWeight: "normal",
        border: "none",
        textAlign: "left",
        backgroundImage: "none !important",
        backgroundColor: "transparent",
        outline: "none",
    },

    events_container: {
        width: "calc(100% - 30px)",
        margin: "10px auto",
        padding: 1,
        borderRadius: 14,
        boxShadow: "0px 4px 3px rgb(0, 0, 0, 15%)",
        '&:last-child': {
            marginBottom: "3rem",
        }
    },

    events_head: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(174,213,163,1) 0%, rgba(125,184,90,1) 100%)",
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        justifyContent: "space-between",
    },

    events_head_unread: {
        color: "#ffffff",
        background: "linear-gradient(180deg, rgba(177,177,187,1) 0%, rgba(200,200,200,1) 100%)",
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        justifyContent: "space-between",
    },

    events_body: {
        color: "#989ba4",
        fontSize: 14,
        backgroundColor: "#ffffff",
        padding: "20px 10px 20px 20px",
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        '& .time': {
            width: "90%",
            textAlign: "left",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& div': {
                width: "100%",
            }
        },
        '& .actions': {
            width: "10%",
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            '& svg': {
                fontSize: 25,
            }
        },
    },

    static_text: {
        color: "#ffffff",
        fontSize: 16,
        borderRadius: 14,
        border: "4px solid #b3d7a3",
        height: '67px',
        width: "calc(100% - 30px)",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "6px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "17px 18px",
    },

    grid: {
        width: "100%",
        alignContent: 'center top',
    },
    curola: {
        maxWidth: "37px",
    },
    refresh: {
        '& svg': {
            marginTop: "0px !important",
            marginRight: "-16px !important",
        }
    }


}));


export const EventListInduo = props => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [events, setEvents] = React.useState([]);
    /**
     const [user, setUser] = React.useState({
        username: '',
        password: '',
        name: '',
        email: '',
        address: '',
        postalAddress: '',
        mobilePhone: '',
        zip: '',
        messageChannel: '',
        additionalContacts: [],
        devices: []
    });
     */
    const itemsPerPage = 10;
    const [page, setPage] = React.useState(1);
    const noOfPages = Math.ceil(events.length / itemsPerPage);

    const handleChange = (event, value) => {
        setPage(value);

        if (value > noOfPages) {
            setPage(1);
        }
    };

    useEffect(() => {
        console.log("selected user: " + location.state.currentUser.username);
        const getEvents = async () => {
            const eventos = await EventService.getEvents(location.state.currentUser.username);
            setEvents(eventos);
        };
        getEvents().catch((err) => {
            console.time("Error fetching alarms.");
            setEvents({});

        });
        let entryString = '';
        events.forEach(event => {
            entryString += "Events:\n";
            entryString += JSON.stringify(event) + "\n";
        })
        console.time("All the events: \n" + entryString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     const handleEventClick = (eventId) => {
        history.push("/event_detail/" + eventId);
        console.time("eventId: " + eventId);
    };

     const handleRefresh = () => {
        console.time("Uppdatera listan!")
    }
     */

    console.log('events.length', events.length);
    console.log('noOfPages ', noOfPages);
    console.log('page ', page);



    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{ display: "block" }}>
                    <Grid item xs={12} className="title_heading">
                        <h1 style={{ marginBottom: 0 }}>Sensorlogg</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push("/home")} />
                    </Grid>
                    <br />
                    {/* <IconButton
                        onClick={() => handleRefresh()}
                        color="greyScale">
                        <AutorenewRoundedIcon fontSize="large" />
                    </IconButton> */}
                    <Grid item xs={12}>
                        <h3>Listvy</h3>
                    </Grid>
                    <div className="content-pane">
                        {events.length > 0 ? events
                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            .map(curEvent => {
                                let isMovement = false;
                                const pirObject = curEvent.eventValues.find(PIR => PIR.name === "PIR")
                                if (pirObject.value === "1") {
                                    isMovement = true;
                                } else {
                                    isMovement = false;
                                }
                                return (
                                    <Paper sx={{borderRadius: 4}} key={curEvent.id} variant="outlined" className={classes.events_container}>
                                        <Grid container direction="row" className={isMovement === true ? classes.events_head : classes.events_head_unread}>
                                            <Grid item xs={12} className={classes.message_cell}>
                                                {isMovement ? "Rörelse" : "Stilla"}
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" className={classes.events_body}>
                                            <Grid item xs={2} style={{textAlign:"left"}}>
                                                <img className={classes.curola} src="/images/CurolaMiniOK.svg"
                                                     alt="Curola meddelar."/>
                                            </Grid>
                                            <Grid item xs={10} style={{textAlign:"left"}}>
                                                <div>
                                                    <small>Device ID:</small> {curEvent.deviceId} <br/>
                                                    <small>Server Timesstamp:</small> {curEvent.dateTime !== undefined ? TimeHelperService.getDateFromDateString(curEvent.dateTime) + "T" + TimeHelperService.getTimeFromDateString(curEvent.dateTime) : "ingen timestamp tillgänglig"}<br/>
                                                    <small>Sensor Timesstamp:</small> {curEvent.sensorDateTime !== undefined ? TimeHelperService.getDateFromDateString(curEvent.sensorDateTime) + "T" + TimeHelperService.getTimeFromDateString(curEvent.sensorDateTime) : "ingen timestamp tillgänglig"}<br/>
                                                </div>
                                                { curEvent.eventValues.sort( (a, b) => b.name - a.name)
                                                    .map((currentValue, i) => {
                                                    return (
                                                    <div key={i}><small>{currentValue.name}:</small> {currentValue.value}</div>
                                                    );
                                            }) }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )
                            }) : <b>Log is empty</b>
                        }
                        <br />
                    </div>
                    <Divider />
                    <Box component="span" paddingLeft={3}>
                        <Pagination
                            count={events}
                            page={page}
                            onChange={handleChange}
                            defaultPage={1}
                            variant="outlined"
                            color="secondary"
                            size="large"
                            showFirstButton
                            classes={{ ul: classes.paginator }}
                        />
                    </Box>

                    <Grid item xs={12}>
                        <br /><br />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 35 }}><br /></Grid>
                </Grid>
            </div>
        </div>
    );
};